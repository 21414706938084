import ApiService from "../Components/ApiService";

export const FETCH_TEST_DRIVE_SUCCESS = "FETCH_TEST_DRIVE_SUCCESS";
export const FETCH_TEST_DRIVE_FAILED = "FETCH_TEST_DRIVE_FAILED";

const fetchTestDriveSuccess = (data) => ({
    type: FETCH_TEST_DRIVE_SUCCESS,
    payload: data,
});

const fetchTestDriveFailure = (error) => ({
    type: FETCH_TEST_DRIVE_FAILED,
    payload: error,
});

export const fetchTestDriveRequests = (token) => (dispatch) => {
    ApiService.fetchTestDriveRequests(token)
        .then((data) => {
            console.log("calling get test drive request api", data);
            dispatch(fetchTestDriveSuccess(data.payload));
        })
        .catch((error) => {
            console.log("Error occurred while calling test drive request api", error);
            dispatch(fetchTestDriveFailure(error));
        });
};

export const updateTestDriveRequest = (token, request) => (dispatch) => {
    ApiService.updateTestDriveRequest(token, request)
        .then((data) => {
            console.log("calling get test drive request update api", data);
        })
        .catch((error) => {
            console.log("Error occurred while calling test drive request update api", error);
        });
};