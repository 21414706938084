const colorName = require('color-name');
export const ApiUrls = {
    baseUrl: "https://dev.electrawheeler.in",
    ewBaseUrl: "/api/v0/evDetails",
    testDriveUrl: "/api/v0/test-drive",
    stateInfoUrl: "https://api.countrystatecity.in",
    citiesUrl: "https://api.countrystatecity.in/v1/countries/IN/states",
    getEvColorsUrl: "/api/v0/evDetails/search-colors",
    getReviews: "/api/v0/review",
    postReview: "/api/v0/review",
    fetchUserDetails: "/api/v0/user",
    fileUpload: "/api/v0/file-upload-controller/upload",
    addOrRemoveFromWishList: "/api/v0/user/add-into-wiselist",
    testDriveUpdateRequest: "/api/v0/test-drive/update-status",
    fetchArticlesUrl: "/api/v0/blog/search",
    uploadEvDetails: "/excel/upload",
    fetchOnRoadPriceUrl: "/api/v0/ev-pricing",
    fetchCities: "/api/v0/ev-pricing/cities",
    sendOtpUrl: '/api/v0/login/mobile/send-otp',
    submitOtpUrl: '/api/v0/login/verify-otp',
    fetchFaqUrl: '/api/v0/faq',
    homePageUrl:'/api/v0/home-page'
}

export function getColorCodeByName(name) {
    // Convert color name to lowercase for case-insensitive matching
    const processedColors = processColor(name);
    console.log("searching color code for: ", processedColors);
    const lowerCaseColorName = processedColors.toLowerCase();
    if (colorName[lowerCaseColorName]) {
        return colorName[lowerCaseColorName];
    } else {
        return lowerCaseColorName; // Default to black
    }
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
};


const processColor = (color) => {
    // Remove the first word and any leading or trailing newline characters
    const colorArray = [
        "Red", "Blue", "Green", "Yellow", "Orange", "Purple", "Pink", "Brown",
        "Cyan", "Magenta", "Teal", "Lime", "Indigo", "Violet", "Maroon", "Olive",
        "Navy", "Aquamarine", "Turquoise", "Silver", "Gray", "Black", "White",
        "AliceBlue", "AntiqueWhite", "Aqua", "Aquamarine", "Azure", "Beige", "Bisque",
    ];
    const lowercaseColorArray = colorArray.map(color => color.toLowerCase());
    const values = color.trim().split(/\s+/);
    console.log("color values are", values);
    if (values.length > 1) {
        const colorValues = values.filter(value => lowercaseColorArray.includes(value.toLowerCase()))[0];
        if (colorValues !== undefined) {
            console.log("fetched color is", colorValues);
            return colorValues.toLowerCase();
        }
    }
    return values[0];
}


export const ApiConfig = {
    CountryStateCityApiKey: "eFZzUHYzVlRkSmo0aVVGQVJyTTZBd3JPSjNYZkkwc1dJdm1rc3FnMQ=="
}