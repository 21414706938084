import React, {useEffect, useState} from 'react';
import {Container, Form} from 'react-bootstrap';
import {useDropzone} from "react-dropzone";
import {useToken} from "../TokenContext";
import {resetFailureMsg, resetSuccessMsg, uploadDocuments} from "../../Actions/BackOfficeActions";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {useStore} from "../StoreContext";

function BulkUploadDocumentSection(props) {
    const {uploadDocumentInProgress, successMessage, failureMessage} =
        useSelector(state => state.backOfficeReducers);

    console.log("uploadDocumentInProgress, successMessage, failureMessage",
        uploadDocumentInProgress, successMessage, failureMessage);

    const {token} = useToken();
    const store = useStore();

    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);

    const onDrop1 = acceptedFiles => {
        // Set the first accepted file
        setFile1(acceptedFiles[0]);
    };

    const onDrop2 = acceptedFiles => {
        // Set the second accepted file
        setFile2(acceptedFiles[0]);
    };

    const {getRootProps: getRootProps1, getInputProps: getInputProps1} = useDropzone({
        accept: '.xls,.xlsx',
        onDrop: onDrop1
    });

    const {getRootProps: getRootProps2, getInputProps: getInputProps2} = useDropzone({
        accept: '.xls,.xlsx',
        onDrop: onDrop2
    });

    const handleSubmit = (event, file, reportType) => {
        event.preventDefault();
        if (file) {
            console.log('File submitted:', file);
            store.dispatch(uploadDocuments(token, file, reportType));
        } else {
            console.log('No file selected');
        }
    };

    useEffect(() => {
        if (successMessage !== null)
            toast.success(successMessage);
        store.dispatch(resetSuccessMsg());
    }, [successMessage]);

    useEffect(() => {
        if (failureMessage !== null)
            toast.error(failureMessage);
        store.dispatch(resetFailureMsg());
    }, [failureMessage]);

    return (
        <div className="bulk-upload-document-page-container">
            <Container>
                <h1>Upload Documents</h1>
                <div className="upload-form-container">
                    <Form onSubmit={event => handleSubmit(event, file1, "EV_PRICING_REPORT")}>
                        <Form.Group controlId="formExcel1">
                            <Form.Label>Choose Excel File For EV Pricing</Form.Label>
                            <div {...getRootProps1()} className="dropzone">
                                <input {...getInputProps1()}/>
                                <button className="upload-btn" type="button">Choose File</button>
                                {file1 ? (
                                    <p>Selected file: {file1.name}</p>
                                ) : (
                                    <p>No file selected</p>
                                )}
                            </div>
                        </Form.Group>
                        {!uploadDocumentInProgress ? (<button className="submit-btn" type="submit">
                            Submit
                        </button>) : <button className="upload-btn1">Uploading...</button>}
                    </Form>
                    <Form onSubmit={event => handleSubmit(event, file2, "ELECTRIC_VEHICLE_FILE")}>
                        <Form.Group controlId="formExcel2">
                            <Form.Label>Choose Excel File For EV Details</Form.Label>
                            <div {...getRootProps2()} className="dropzone">
                                <input {...getInputProps2()}/>
                                <button className="upload-btn" type="button">Choose File</button>
                                {file2 ? (
                                    <p>Selected file: {file2.name}</p>
                                ) : (
                                    <p>No file selected</p>
                                )}
                            </div>
                        </Form.Group>
                        <button className="submit-btn" type="submit">
                            Submit
                        </button>
                    </Form>
                </div>
            </Container>
        </div>
    );
}

export default BulkUploadDocumentSection;