import React, {useState, useEffect} from "react";
import '../../Css/ProductInfo.css';
import ProductInfo from "./ProductInfo";
import TopMostTrendingBikes from "../Home/TopMostTrendingBikes";
import ReviewForm from "../Form/ReviewForm";
import {connect, useSelector} from "react-redux";
import Reviews from "./Reviews";
import ProductComparison from "./ProductComparison";
import RoadLine from "./RoadLine";
import {fetchProductDetails} from "../../Actions/ProductActions";
import {useParams} from "react-router-dom";
import store from "../Utils/Store";
import ProductSpecifications from "./ProductSpecifications";
import FAQ from "../Section/FAQ";

const ProductDetails = () => {
    const {titleUrl} = useParams();
    const [modelName, setModelName] = useState(null);
    const productDetails = useSelector(state => state.productReducer.product);

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("productTitle", titleUrl);
        store.dispatch(fetchProductDetails({titleUrl: titleUrl}));
        setModelName(modelName);
    }, [titleUrl]);


    const request = {
        "direction": "ASC", "limit": 100, "offset": 0, "modelName": modelName
    }

    const [isFormVisible, setIsFormVisible] = useState(false);

    const showForm = () => {
        setIsFormVisible(true);
    };

    const closeForm = () => {
        setIsFormVisible(false);
    };


    return (
        <div className="product-details-parent-container">

            <ProductInfo/>
            <RoadLine headline={"SPECIFICATIONS"}/>
            <ProductSpecifications/>
            <RoadLine headline={"ELECTRA WHEELERS COMPARISON"}/>
            <ProductComparison/>
            <RoadLine headline={"SIMILAR PRODUCTS"}/>
            <div className="similar-product-list-container">
                <TopMostTrendingBikes title="" request={request} imgHeight={200}/>
            </div>
            <RoadLine headline={"CUSTOMER REVIEWS"}/>
            <div className="review-container">
                <button onClick={showForm} className="write-review-btn">Write Review</button>
                {isFormVisible && <ReviewForm onClose={closeForm} productName={modelName}/>}
                <Reviews productName={modelName}/>
            </div>
            {productDetails && <FAQ referenceId={productDetails.brandId} pageName={'EV_DETAILS_PAGE'}/>}
        </div>
    );
};

const mapStateToProps = state => ({
    product: state.productReducer.product
});

export default connect(mapStateToProps)(ProductDetails);
