import ApiService from "../Components/ApiService";

export const UPLOAD_DOCUMENT_IN_PROGRESS = "UPLOAD_DOCUMENT_IN_PROGRESS";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAILED = "UPLOAD_DOCUMENT_FAILED";
export const RESET_SUCCESS_MSG = "RESET_SUCCESS_MSG";
export const RESET_FAILURE_MSG = "RESET_FAILURE_MSG";

const uploadDocumentInProgress = () => ({
    type: UPLOAD_DOCUMENT_IN_PROGRESS,
    payload: null
})

const uploadDocumentSuccess = (msg) => ({
    type: UPLOAD_DOCUMENT_SUCCESS,
    payload: msg
})

const uploadDocumentFailed = (msg) => ({
    type: UPLOAD_DOCUMENT_FAILED,
    payload: msg
})
export const resetSuccessMsg = () => ({
    type: RESET_SUCCESS_MSG,
    payload: null
})
export const resetFailureMsg = () => ({
    type: RESET_FAILURE_MSG,
    payload: null
})


export const uploadDocuments = (token, file, reportType) => (dispatch) => {
    dispatch(uploadDocumentInProgress());
    ApiService.UploadEvDetailsFile(file, token, reportType).then(data => {
        dispatch(uploadDocumentSuccess("Document uploaded successfully"));
    }).catch((error) => {
        console.error('Error while uploading file:', error);
        dispatch(uploadDocumentFailed("Something went wrong!"));
    });
}

