import apiService from "../Components/ApiService";

export const FETCH_FAQ_SUCCESS = "FETCH_FAQ_SUCCESS";
export const fetchFaqSuccess = (data) => ({
    type: FETCH_FAQ_SUCCESS,
    payload: data
});
export const fetchFaqs = (referenceId, pageName) => (dispatch) => {
    apiService.fetchFaqs(referenceId, pageName)
        .then((data) => {
            console.log('faq data', data);
            dispatch(fetchFaqSuccess(data.payload))
        }).catch((error) => {
        console.log("error occurred while receiving faqs", error);
    })
}