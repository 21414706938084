import {FETCH_TEST_DRIVE_FAILED, FETCH_TEST_DRIVE_SUCCESS} from "../Actions/TestDriveActions";

const initialState = {
    testDriveRequests: [],
    error: null
}

const testDriveReducers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEST_DRIVE_SUCCESS:
            return {
                ...state,
                testDriveRequests: action.payload
            }
        case FETCH_TEST_DRIVE_FAILED:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}

export default testDriveReducers;