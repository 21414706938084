import React, {useEffect, useState} from 'react';
import {useStore} from "../StoreContext";
import "../../Css/PricingDetails.css"
import {
    changePricingDetailsDisplay,
    fetchOnRoadPrice,
    HIDE_PRICING_DETAILS
} from "../../Actions/ProductActions";
import {useSelector} from "react-redux";

function EvPricingDetails() {
    const store = useStore();
    const showPricingDetails = useSelector(state => state.propertiesReducers.showPricingDetails);
    const pricingDetail = useSelector(state => state.productReducer.pricingDetail);
    const cities = useSelector(state => state.productReducer.cities);
    const [selectedCity, setSelectedCity] = useState(null);
    console.log("pricing details", showPricingDetails);


    function handleBackgroundClick() {
        console.log("hide on road price");
        store.dispatch(changePricingDetailsDisplay(HIDE_PRICING_DETAILS, false));
    }

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    }

    useEffect(() => {
        if (selectedCity !== null && selectedCity !== undefined)
            store.dispatch(fetchOnRoadPrice(selectedCity, pricingDetail.modelName));
    }, [selectedCity]);

    return (
        <div>
            {showPricingDetails && <div className="form-container" onClick={handleBackgroundClick}>
                <div className="pricing-main-container">
                    <div className="pricing-select-options">
                        <label
                            style={{marginRight: 10}}
                        >Location</label>
                        <select
                            value={selectedCity}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            onChange={handleCityChange}
                            style={{height: 30, paddingLeft: 5}}
                        >

                            {cities && Array.isArray(cities) && cities.map((city, index) => (
                                (city !== null && city !== undefined) &&
                                <option key={index}>{city}</option>
                            ))}
                        </select>
                    </div>

                    <div className="pricing-container" onClick={(e) => {
                        e.stopPropagation();
                    }}>
                        <ul className="pricing-keys">
                            <li className="heading-text">Model Name</li>
                            <hr/>
                            <li>effective ex showroom price</li>
                            <li className="green-text">central subsidy</li>
                            <li className="green-text">state subsidy</li>
                            <li className="green-text">other discount</li>
                            <li className="green-text">company promotional</li>
                            <li>insurance</li>
                            <li>road tax</li>
                            <li>registration charge</li>
                            <hr/>
                            <li className="heading-text">on road price</li>
                            <hr/>
                        </ul>
                        {pricingDetail && (
                            <ul className="pricing-values">
                                <li className="heading-text">{pricingDetail.modelName}</li>
                                <hr/>
                                <li>&#x20b9;{pricingDetail?.exShowroomPrice}</li>
                                <li className="green-text">- &#x20b9;{pricingDetail?.centralSubsidy}</li>
                                <li className="green-text">- &#x20b9;{pricingDetail?.stateSubsidy}</li>
                                <li className="green-text">- &#x20b9;{pricingDetail?.otherDiscount}</li>
                                <li className="green-text">- &#x20b9;{pricingDetail?.companyPromotional}</li>
                                <li>+ &#x20b9;{pricingDetail?.insurance}</li>
                                <li>+ &#x20b9;{pricingDetail?.roadTax}</li>
                                <li>+ &#x20b9;{pricingDetail?.registrationCharge}</li>
                                <hr/>
                                <li className="heading-text">&#x20b9;{pricingDetail?.onRoadPrice}</li>
                                <hr/>
                            </ul>
                        )}
                    </div>
                </div>
            </div>}

        </div>
    )
}

export default EvPricingDetails;