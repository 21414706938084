import React from 'react'
import {useState, useEffect} from 'react';
import ApiService from "../ApiService";
import {useLocation} from "react-router-dom";
import '../../Css/ProductComparison.css';
import {useSelector} from "react-redux";

const ProductComparison = () => {
    const product = useSelector(state => state.productReducer.product);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [request, setRequest] = useState(null);
    const currentPage = 1;
    const location = useLocation();
    const productsPerPage = 12;

    useEffect(() => {
        console.log("fetch request", request)
        if (request !== null)
            fetchData(request);
    }, [request]);

    useEffect(() => {
        window.scrollTo(0, 0);
        let newRequest = null;
        if (location.state !== null) {
            newRequest = location.state.newRequest;
            console.log("new request", newRequest);
        }
        if (newRequest !== null) {
            setRequest(newRequest);
        }
    }, []);

    function fetchData(request) {
        console.log("Request received to fetch data ", request);
        ApiService.fetchProductData(request)
            .then((fetchedData) => {
                setData(fetchedData);
            })
            .catch((fetchError) => {
                setError(fetchError);
            });
    }

    useEffect(() => {
        if (currentPage !== null && request === null) {
            const offset = (currentPage - 1) * productsPerPage;
            setRequest({
                limit: productsPerPage,
                offset: offset,
            });
        }
    }, [currentPage]);

    return (
        <div>
            {product && <div className='comparison-table'>
                <div className='compare'>Compare {product.brandName} {product.modelName} with Others</div>
                <div className='table-wrapper'>
                    <table>
                        <thead>
                        <tr>
                            <th className='property'>BIKE NAME</th>
                            <th className='current'>{product.brandName} {product.modelName}</th>
                            {data.payload?.data.map((productDetails) => {
                                if (productDetails.brandId !== product.brandId) {
                                    return <th>{productDetails.brandName} {productDetails.modelName}</th>
                                }
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th className='property'>Fast charging</th>
                            <td className='current'>{product?.homePortableChargingTime?.substring(0, 22)}</td>
                            {data.payload?.data.map((productDetails) => {
                                if (productDetails.brandId !== product.brandId) {
                                    return <td>{productDetails.homePortableChargingTime.substring(0, 22)}</td>
                                }
                            })}
                        </tr>
                        <tr>
                            <th className='property'>Mileage</th>
                            <td className='current'>{product.idcRange}</td>
                            {data.payload?.data.map((productDetails) => {
                                if (productDetails.brandId !== product.brandId) {
                                    return <td>{productDetails.idcRange}</td>
                                }
                            })}
                            {/* {productDetails.fastChargingSupport.substring(0,22)}
            {productDetails.idcRange}
            {productDetails.topSpeed}
            {productDetails.motorPeakPowerCapacity}
            {productDetails.kerbWeight}
            {productDetails.batteryCapacity} */}
                        </tr>
                        <tr>
                            <th className='property'>Speed</th>
                            <td className='current'>{product.topSpeed}</td>
                            {data.payload?.data.map((productDetails) => {
                                if (productDetails.brandId !== product.brandId) {
                                    return <td> {productDetails.topSpeed}</td>
                                }
                            })}
                        </tr>
                        <tr>
                            <th className='property'>Motor power</th>
                            <td className='current'>{product.motorPeakPowerCapacity}</td>
                            {data.payload?.data.map((productDetails) => {
                                if (productDetails.brandId !== product.brandId) {
                                    return <td>{productDetails.motorPeakPowerCapacity}</td>
                                }
                            })}
                        </tr>
                        <tr>
                            <th className='property'>Weight</th>
                            <td className='current'>{product.kerbWeight}</td>
                            {data.payload?.data.map((productDetails) => {
                                if (productDetails.brandId !== product.brandId) {
                                    return <td>{productDetails.kerbWeight}</td>
                                }
                            })}
                        </tr>
                        <tr>
                            <th className='property'>Battery capacity</th>
                            <td className='current'>{product.batteryCapacity}</td>
                            {data.payload?.data.map((productDetails) => {
                                if (productDetails.brandId !== product.brandId) {
                                    return <td>{productDetails.batteryCapacity}</td>
                                }
                            })}
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>}
        </div>
    )
}

export default ProductComparison;
