import {ApiConfig, ApiUrls} from "./Utils/ConfigUtil";
import tokenService from "./TokenService";
import {toast} from "react-toastify";
import axios from "axios";

class ApiService {
    static async fetchProductData(request) {
        return fetch(`${ApiUrls.baseUrl + ApiUrls.ewBaseUrl}/search`, {
            method: "POST",
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        }).then((response) => {
            console.log("Response", response);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            return response.json();
        }).then((data) => data).catch((error) => {
            console.error('Error fetching product data:', error);
            throw error;
        });
    }

    static async fetchHomePageData() {
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.homePageUrl}`, {
            method: "GET",
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                console.log("home page response", response);
                if (!response.ok) {
                    throw new Error("failed to fetch home page data");
                }
                return response.json();
            })
            .catch((error) => {
                console.error("Error fetching home page data:", error);
                throw error;
            });
    }

    static async fetchBrands(request) {
        return fetch(`${ApiUrls.baseUrl + ApiUrls.ewBaseUrl}/brand-summary`, {
            method: "GET",
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
            },
        }).then((response) => {
            console.log("Response received from brand-summary api", response);
            if (!response.ok) {
                throw new Error('Failed to fetch data brand-summary');
            }
            return response.json();
        }).then((data) => data).catch((error) => {
            console.error('Error fetching product data:', error);
            throw error;
        });
    }

    static async fetchAllStates() {
        var headers = new Headers();
        headers.append("X-CSCAPI-KEY", ApiConfig.CountryStateCityApiKey);


        return fetch(`${ApiUrls.stateInfoUrl}/v1/countries/IN/states`, {
            method: "GET",
            headers: headers,
            redirect: 'follow'
        })
            .then((response) => {
                console.log("Response received from indian states api", response);
                if (!response.ok) {
                    throw new Error('Failed to fetch data india state info');
                }
                return response.json();
            })
            .then((data) => data)
            .catch((error) => {
                console.error('Error fetching product data:', error);
                throw error;
            });
    }

    static async fetchAllCities(stateCode) {
        var headers = new Headers();
        headers.append("X-CSCAPI-KEY", ApiConfig.CountryStateCityApiKey);

        return fetch(`${ApiUrls.citiesUrl}/${stateCode}/cities`, {
            method: "GET",
            headers: headers,
            redirect: 'follow'
        })
            .then((response) => {
                console.log("Response received from indian states api", response);
                if (!response.ok) {
                    throw new Error('Failed to fetch data india state info');
                }
                return response.json();
            })
            .then((data) => data)
            .catch((error) => {
                console.error('Error fetching product data:', error);
                throw error;
            });
    }

    // Add more API functions as needed
    static async bookTestDrive(request) {
        const token = localStorage.getItem("token");
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.testDriveUrl}`, {
            method: "POST",
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(request),
        }).then((response) => {
            console.log("Response", response);
            if (!response.ok) {
                throw new Error('Failed to book test drive');
            }
            return response.json();
        }).then((data) => data).catch((error) => {
            console.error('Failed to book test drive:', error);
            throw error;
        });
    }

    static async getColors(brandName, modelName) {
        const values = brandName.trim().split(/\n+/).slice(0);
        console.log("calling get color api");
        brandName = values[0];
        const request = {
            brandName,
            modelName
        }

        return fetch(`${ApiUrls.baseUrl}${ApiUrls.getEvColorsUrl}`, {
            method: "POST",
            body: JSON.stringify(request),
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
            }
        }).then((response) => {
            console.log("Response Received for Ev details get colors api", response);
            if (!response.ok) {
                throw new Error('Failed to fetch ev colors');
            }
            return response.json();
        }).then((data) => data).catch((error) => {
            console.error('Error occurred while calling api', error);
            throw error;
        });
    }

    static async fetchReviews(productName) {
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.getReviews}/${productName}`, {
            method: "GET",
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
            }
        }).then((response) => {
            console.log("Response Received from get review api", response);
            if (!response.ok) {
                throw new Error('Failed to fetch reviews');
            }
            return response.json();
        }).then((data) => data).catch((error) => {
            console.error('Error occurred while calling api get review', error);
            throw error;
        });
    }

    static async postReview(reviewContent, productName) {
        const token = localStorage.getItem("token");
        const body = {
            "review": reviewContent,
            "productName": productName
        }
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.postReview}`, {
            method: "POST",
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(body)
        }).then((response) => {
            console.log("Response Received from post review api", response);
            if (!response.ok) {
                throw new Error('Failed to post review');
            }
            return response.json();
        }).then((data) => data).catch((error) => {
            console.error('Error occurred while calling api posting review', error);
            throw error;
        });
    }

    static async fetchUserData(token) {
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.fetchUserDetails}`, {
            method: "GET",
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        }).then((response) => {
            console.log("Response Received from get user api", response);
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            return response.json();
        }).then((data) => data).catch((error) => {
            console.error('Error occurred while calling api get user details', error);
            throw error;
        });
    }

    static async UploadProductImages(file, token) {
        fetch(`${ApiUrls.baseUrl}${ApiUrls.fileUpload}`, {
            method: 'POST',
            body: file,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
                "accept": "*/*"
            }
        }).then(response => {
            if (response.ok) {
                console.log(`File ${file.name} uploaded successfully!`);
            } else {
                console.error(`Failed to upload file ${file.name}`);
            }
        })
            .catch(error => {
                console.error('Error uploading file:', error);
            });
    }

    static async addAndRemoveFormWishList(action, brandId) {
        const token = tokenService.getToken();
        const url = ApiUrls.addOrRemoveFromWishList + `?action=${action}&brandId=${brandId.toString()}`
        fetch(`${ApiUrls.baseUrl}${url}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "accept": "*/*"
            }
        }).then(response => {
            if (response.ok) {
                console.log(`product added successfully`, response);
            } else {
                console.error(`failed to add product on wishlist`);
            }
            return response.json();
        }).catch(error => {
            console.error('error occurred while adding on wishlist:', error);
        });
    }

    static async fetchTestDriveRequests(token) {
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.testDriveUrl}`, {
            method: "GET",
            headers: {
                "accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        }).then((response) => {
            console.log("Response Received from test drive request api", response);
            if (!response.ok) {
                throw new Error('Failed to fetch test drive request api');
            }
            return response.json();
        }).then((data) => data).catch((error) => {
            console.error('Error occurred while calling test drive request api', error);
            throw error;
        });
    }

    static async updateTestDriveRequest(token, request) {
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.testDriveUpdateRequest}`, {
            method: "POST",
            body: JSON.stringify(request),
            headers: {
                accept: "*/*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        }).then((response) => {
            console.log("Response Received from test drive update api", response);
            if (!response.ok) {
                throw new Error('Failed to fetch test drive request update api');
            }
            return response.json();
        }).then((data) => data).catch((error) => {
            console.error('Error occurred while calling test drive request update api', error);
            throw error;
        });
    }

    static async fetchArticles(request) {
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.fetchArticlesUrl}`, {
            method: "POST",
            body: JSON.stringify(request),
            headers: {
                accept: "*/*",
                "Content-Type": "application/json"
            }
        }).then((response) => {
            console.log("Response Received from search article api", response);
            if (!response.ok) {
                throw new Error('Failed to fetch search article api');
            }
            return response.json();
        }).then((data) => data).catch((error) => {
            console.error('Error occurred while calling search article api', error);
            throw error;
        });
    }

    static async UploadEvDetailsFile(file, token, reportType) {
        // Create a FormData object
        const formData = new FormData();
        formData.append('file', file, file.name); // 'Data.xlsx' is the filename

        // Define request headers
        const headers = {
            'Authorization': `Bearer ${token}`,
            'accept': '*/*'
        };

        // Make a POST request using fetch
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.uploadEvDetails}?reportType=${reportType}`, {
            method: 'POST',
            body: formData,
            headers: headers
        })
            .then(response => {
                if (response.ok) {
                    console.log(`File ${file.name} uploaded successfully!`);
                    return response.json();
                } else {
                    console.error(`Failed to upload file ${file.name}`);
                    throw new Error(`Failed to upload file ${file.name}`);
                }
            })
            .catch(error => {
                console.error('Error uploading file:', error);
                throw error;
            });
    }

    static async fetchOnRoadPrice(city, modelName) {
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.fetchOnRoadPriceUrl}?city=${city}&modelName=${modelName}`, {
            method: "GET",
            headers: {
                accept: "*/*",
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (!response.ok) {
                return response.json();
            }
            return response.json();
        }).then((data) => data).catch((error) => {
            throw error;
        });
    }

    static async fetchCity(modelId) {
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.fetchCities}?modelId=${modelId}`, {
            method: "GET",
            headers: {
                accept: "*/*",
                "Content-Type": "application/json"
            }
        }).then((response) => {
            if (!response.ok) {
                return response.json();
            }
            return response.json();
        }).then((data) => data).catch((error) => {
            throw error;
        });
    }

    static async sendOtp(mobile) {
        const requestBody = {
            phoneNumber: mobile
        };
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.sendOtpUrl}`, {
            method: 'POST',
            headers: {
                accept: "*/*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        }).then((response) => {
            if (!response.ok) {
                toast.error('Failed to send OTP');
                throw new Error('Failed to send OTP');
            }
            console.log('OTP sent successfully:', response);
            toast.success('OTP sent successfully');
            return response.json();
        }).then((data) => data).catch((error) => {
            toast.error('Error sending OTP:', error.message);
            console.error('Error sending OTP:', error.message);
            throw error;
        });
    }

    static async verifyOtp(mobile, otp) {
        return fetch(`${ApiUrls.baseUrl}${ApiUrls.submitOtpUrl}?mobile=${mobile}&otp=${otp}`, {
            method: 'POST',
            headers: {
                accept: "*/*"
            },
            body: null
        }).then((response) => {
            if (!response.ok) {
                toast.error('Failed to submit OTP');
                throw new Error('Failed to submit OTP');
            }
            console.log('OTP verified successfully:', response);
            toast.success('Mobile verified successfully');
            return response.json();
        }).then((data) => data).catch((error) => {
            toast.error('Error submitting OTP:', error.message);
            console.error('Error submitting OTP:', error.message);
            throw error;
        });
    }

    static async fetchFaqs(referenceId, pageName) {
        return fetch(
            `${ApiUrls.baseUrl}${ApiUrls.fetchFaqUrl}?pageId=${referenceId}&pageName=${pageName}`,
            {
                method: 'GET',
                headers: {
                    'Accept': '*/*',
                },
            }
        ).then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).catch((error) => {
            console.error('Error fetching data:', error);
        })
    }
}

export default ApiService;
