class TokenService {
    static setToken(token, expiresIn) {
        const expirationDate = new Date().getTime() + expiresIn * 1000;
        localStorage.setItem('token', token);
        localStorage.setItem('expirationDate', expirationDate.toString());
        console.log("token setup success", this.getToken());
    }

    static getToken() {
        const token = localStorage.getItem('token');
        const expirationDate = localStorage.getItem('expirationDate');

        if (!token || !expirationDate) {
            return null;
        }

        if (new Date().getTime() > expirationDate) {
            console.log("token removing..");
            this.removeToken();
            return null;
        }

        return token;
    }

    static removeToken() {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
    }
}

export default TokenService;
