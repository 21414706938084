import React from 'react';
import {useSelector} from "react-redux";
import {useStore} from "../StoreContext";
import {hideProductInfoHighlightsForm} from "../../Actions/ProductActions";

function ProductInfoHighLightsForm() {
    const showForm = useSelector(state => state.propertiesReducers.productInfoHighLightsForm);
    const store = useStore();
    const handleBackgroundClick = () => {
        store.dispatch(hideProductInfoHighlightsForm());
    };

    const handleChange = () => {

    }

    const handleSubmitButton = () => {

    }

    return (
        <div>
            {showForm && (<div className="form-container" onClick={handleBackgroundClick}>
                <form onSubmit={handleSubmitButton} className="update-product-info-form"
                      onClick={(e) => e.stopPropagation()}>
                    <div>
                        <div className="input-tag">
                            <label className="test-drive-form-lable">charging time</label>
                            <input
                                type="number"
                                name="chargingTime"
                                placeholder="Charging Time"
                                className="test-drive-update-form-input"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="input-tag">
                            <label className="test-drive-form-lable">range</label>
                            <input
                                type="number"
                                name="range"
                                placeholder="Range"
                                className="test-drive-update-form-input"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="input-tag">
                            <label className="test-drive-form-lable">speed</label>
                            <input
                                type="number"
                                name="speed"
                                placeholder="Speed"
                                className="test-drive-update-form-input"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="input-tag">
                            <label className="test-drive-form-lable">motor power</label>
                            <input
                                type="number"
                                name="motorPower"
                                placeholder="Motor Power"
                                className="test-drive-update-form-input"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="input-tag">
                            <label className="test-drive-form-lable">weight</label>
                            <input
                                type="number"
                                name="weight"
                                placeholder="Weight"
                                className="test-drive-update-form-input"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="input-tag">
                            <label className="test-drive-form-lable">battery capacity</label>
                            <input
                                type="number"
                                name="batteryCapacity"
                                placeholder="Battery Capacity"
                                className="test-drive-update-form-input"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="test-drive-form-submit-btn">
                        Update
                    </button>
                </form>
            </div>)}
        </div>
    );
}

export default ProductInfoHighLightsForm;