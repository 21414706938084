import React from "react";
import {useNavigate} from "react-router-dom";
import {RWebShare} from "react-web-share";

import {
    changePricingDetailsDisplay,
    fetchOnRoadPrice, SHOW_PRICING_DETAILS,
} from "../../Actions/ProductActions";
import {useStore} from "../StoreContext";
import {addAndRemoveIntoWishlist} from "../../Actions/UserActions";
import {connect, useSelector} from "react-redux";

const ProductItem = ({product}) => {
    const store = useStore();
    const likedProducts = useSelector(state => state.userReducers.wishlist);
    const navigate = useNavigate();

    const onLike = (productId) => {
        if (likedProducts !== null && Array.isArray(likedProducts) && likedProducts.includes(productId.toString())) {
            store.dispatch(addAndRemoveIntoWishlist("REMOVE", productId.toString()))
        } else {
            store.dispatch(addAndRemoveIntoWishlist("ADD", productId.toString()))
        }
    };

    const openProductDetails = (product) => {
        console.log("product details page selected", product);
        // TODO removing for now
        // store.dispatch(fetchProductDetails(product));
        navigate(`/details/${product.titleUrl}`);
        // navigate(`/product-details/${product.modelName.replace(/\s+/g, '-')}`);
    };


    const handleShowPricingDetails = (modelName) => {
        store.dispatch(changePricingDetailsDisplay(SHOW_PRICING_DETAILS, true));
        store.dispatch(fetchOnRoadPrice("Agra", modelName));
    }

    return (
        <div className="product-list-cart">
            <div className="product-list-cart-header">
                <img src={product.logo} alt="product-logo" className="product-list-logo"/>
                <div>
                    <img
                        src={likedProducts !== null && Array.isArray(likedProducts) &&
                        likedProducts.includes(product.brandId.toString()) ?
                            "/images/red-heart.png" : "/images/heart.png"}
                        alt="like"
                        className="product-list-logo2"
                        onClick={() => onLike(product.brandId)}
                    />
                    <RWebShare
                        data={{
                            text: product.description,
                            url: window.location.href,
                            title: product.modelName,
                        }}
                        onClick={() =>
                            console.log("shared successfully!")
                        }
                    >
                        <img src="/images/share.png" alt="share" className="product-list-logo2"/>
                    </RWebShare>
                </div>
            </div>
            <div className="product-list-cart-img-container">
                {Array.isArray(product.images.bike_images) &&
                product.images.bike_images.length > 0 ? (
                    <div key={product.brandId}
                         onClick={() => openProductDetails(product)}
                         style={{cursor: "pointer"}}
                    >
                        <img
                            src={product.images.bike_images[0]}
                            alt={`
        Product
        1`}
                            className="product-list-cart-img"

                        />
                    </div>
                ) : null}
            </div>
            <div className="product-list-cart-footer">
                <p>{product.title}</p>
                <div
                    style={{
                        marginBottom: 10,
                        fontSize: 15,
                        display: "flex",
                        flexWrap: "wrap"
                    }}
                >
                    <span style={{
                        fontWeight: 500,
                        marginBottom: 5
                    }}>Ex-Showroom Price: &#8377;{product.exShowroomPrice}/- </span>
                    <span
                        style={{
                            fontWeight: 300,
                            color: "black",
                            cursor: "pointer",
                            background: "lightgray",
                            padding: 3,
                            borderRadius: 10,
                            fontSize: 12
                        }}
                        onClick={() => handleShowPricingDetails(product.modelName)}>Get On Road Price</span>
                </div>


                {/*<TestDriveButton divClassName="product-list-footer-btn-container"/>*/}
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    wishlist: state.userReducers.wishlist,
});

export default connect(mapStateToProps)(ProductItem);