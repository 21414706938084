export const SHOW_PRODUCT_HEADER_FORM = 'SHOW_PRODUCT_HEADER_FORM';
export const SHOW_EMI_CALCULATOR_FORM = 'SHOW_EMI_CALCULATOR_FORM';
export const HIDE_PRODUCT_HEADER_FORM = 'HIDE_PRODUCT_HEADER_FORM';
export const HIDE_TEST_DRIVE_UPDATE_FORM = 'HIDE_TEST_DRIVE_UPDATE_FORM';
export const SHOW_TEST_DRIVE_UPDATE_FORM = 'SHOW_TEST_DRIVE_UPDATE_FORM';
export const showProductHeaderForm = () => ({
    type: SHOW_PRODUCT_HEADER_FORM
});

export const showEmiCalculatorForm = (value) => ({
    type: SHOW_EMI_CALCULATOR_FORM,
    payload: value
})

export const hideProductHeaderForm = () => ({
    type: HIDE_PRODUCT_HEADER_FORM
});

export const showTestDriveUpdateForm = () => ({
    type: SHOW_TEST_DRIVE_UPDATE_FORM
});

export const hideTestDriveUpdateForm = () => ({
    type: HIDE_TEST_DRIVE_UPDATE_FORM
});