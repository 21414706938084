import React, {useState} from "react";
import "../../Css/ReviewForm.css";
import apiService from "../ApiService";
import {useStore} from "../StoreContext";
import {addReview} from "../../Actions/ProductActions";
import {toast} from "react-toastify"; // Import your CSS file for styling

const ReviewForm = ({onClose, productName}) => {
    const [reviewContent, setReviewContent] = useState("");
    const store = useStore();

    const handleReviewChange = (e) => {
        setReviewContent(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Review submitted:", reviewContent);
        apiService.postReview(reviewContent, productName).then((data) => {
            console.log("post review api data", data);
            if (data.payload !== undefined) {
                store.dispatch(addReview(data));
                toast.success("thanks for the review");
            } else {
                toast.error("Something went wrong!");
            }
        }).catch((error) => {
            console.log("error while submit review", error);
            toast.error("Something went wrong!");
        })
        // Close the form
        onClose();
    };

    return (
        <div className="review-form-overlay">
            <div className="review-form">
                {/*<h2>Write a Review</h2>*/}
                <textarea
                    value={reviewContent}
                    onChange={handleReviewChange}
                    placeholder="Write your review..."
                    style={{border: "1px solid grey", padding: 10, borderRadius: 5}}
                />
                <button onClick={handleSubmit}
                        style={
                            {
                                color: "lightseagreen",
                                background: "white",
                                border: "1px solid lightseagreen",
                                borderRadius: 3
                            }
                        }
                >Submit
                </button>
            </div>
        </div>
    );
};

export default ReviewForm;
