import ApiService from "../Components/ApiService";

export const FETCH_ARTICLE_SUCCESS = "FETCH_ARTICLE_SUCCESS";
export const FETCH_ARTICLE_DETAIL_SUCCESS = "FETCH_ARTICLE_DETAIL_SUCCESS";
export const FETCH_ARTICLE_FAILED = "FETCH_ARTICLE_FAILED";

export const fetchArticleSuccess = (articles) => ({
    type: FETCH_ARTICLE_SUCCESS,
    payload: articles
})

export const fetchArticleFailed = (error) => ({
    type: FETCH_ARTICLE_FAILED,
    payload: error
})

export const fetchArticleDetailSuccess = (article) => ({
    type: FETCH_ARTICLE_DETAIL_SUCCESS,
    payload: article
})

export const fetchArticles = (searchRequest) => (dispatch) => {
    ApiService.fetchArticles(searchRequest).then(data => {
        console.log("fetched article successfully", data);
        dispatch(fetchArticleSuccess(data.payload.data));
    }).catch(error => {
        dispatch(fetchArticleFailed(error));
    });
}


export const fetchArticleDetails = (searchRequest) => (dispatch) => {
    ApiService.fetchArticles(searchRequest).then(data => {
        console.log("fetched article details successfully", data);
        dispatch(fetchArticleDetailSuccess(data.payload.data[0]));
    }).catch(error => {
        console.log("error occurred while calling fetched article", error);
        dispatch(fetchArticleFailed(error));
    });
}