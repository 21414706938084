import React from 'react';
import "bootstrap/dist/css/bootstrap.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../Css/Section5.css'
import '../Css/Footer.css';

function Footer() {
    return (
        <footer className="widi">
            <div id="holder">
                <div id="leftbox">
                    <div className="icons">
                        <a href="#"><img src="/page/fb.svg" alt=""/></a>
                        <a href=""><img src="/page/wa.svg" alt=""/></a>
                        <a href="https://twitter.com/Electra_Wheeler"><img src="/page/tw.svg" alt=""/></a>
                        <a href="https://instagram.com/electra_wheeler?igshid=OGQ5ZDc2ODk2ZA=="><img
                            src="/page/insta.svg" alt=""/></a>
                        <a href="https://www.linkedin.com/company/electra-wheeler/"><img src="/page/li.svg" alt=""/></a>
                        <a><img src="/page/yt.svg" alt=""/></a>
                        <i className="/Product page/eoa-eoa-twitter-square"></i>
                    </div>
                </div>


            </div>

            <div id="below">
                <div>
                    <h3>Office Address</h3>
                    <h4 className="address">ElectraWheeler Company IIT MADRAS, <br/> CHENNAI, INDIA, 489201</h4>
                    <img src="/images/iitm.jpeg" alt="iitm_image" className="footer-image"/>
                    <img src="/images/nirmaan.jpeg" alt="nirmaan" className="footer-image"/>
                </div>
                {/*<h5 className="email">*/}
                {/*    Email Address: <span className="chhotu">electrawheeler@iitmadras.in </span>*/}
                {/*</h5>*/}
                <div>
                    <h5 className="phone">Contact Number: +919174288939</h5>
                    <h5 className="email">
                        Support Email: <span className="chhotu">electrawheeler@gmail.com </span>
                    </h5>
                    <h5 className="cr">Copyright © 2023 Electra Wheeler. All rights reserved</h5>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
