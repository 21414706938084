import {React, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import "../../Css/TestDrive.css";
import ApiService from "../ApiService";


const Checkpoint = ({title, selected, onClick, isLast}) => {
    const showDivider = !isLast;

    const boxStyles = {
        margin: '0 48px',
        cursor: 'pointer',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        backgroundColor: selected ? '#17ce17' : '#CBD5E0',
        textAlign: 'center',
        position: 'relative'
    };

    const textStyles = {
        fontSize: '8px',
        marginTop: '2px',
        fontWeight: selected ? '500' : 'normal',
        whiteSpace: 'nowrap',
        position: 'absolute',
        top: '24px',
        left: '-14px'
    };

    const dividerStyles = {
        position: 'relative',

        left: '20px',
        bottom: '6px',
        width: '96px',
        borderWidth: '2px',
        borderColor: selected ? '#17ce17' : '#CBD5E0'
    };

    return (
        <div style={boxStyles} onClick={onClick}>
            <span style={textStyles}>{title}</span>
            {showDivider && <hr style={dividerStyles} onClick={onClick}/>}
        </div>
    );
};


const TestDrive1 = () => {
    const [selectedCheckpoints, setSelectedCheckpoints] = useState([]);
    const user = useSelector(state => state.userReducers.user);
    const [selectedValue, setSelectedValue] = useState('');
    const [products, setProducts] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {

        if (user.testDriveResponses !== null && user.testDriveResponses[0] !== undefined) {
            const modelNames = user.testDriveResponses[0].modelNames;
            const promises = modelNames.map(modelName => {
                const request = {modelNames: [modelName]};
                return ApiService.fetchProductData(request);
            });

            Promise.all(promises)
                .then(fetchedData => {
                    const allProducts = fetchedData.map(response => response.payload);
                    setProducts(allProducts);
                    setLoading(false);
                })
                .catch(fetchError => {
                    console.error("product fetch api call error:", fetchError);
                    setError(fetchError);
                    setLoading(false);
                });
        }
    }, []);


    const checkpoints = [
        "Vehicle Selection",
        "Location Confirmation",
        "License Verification",
        "Test Drive Experience",
    ];

    const handleCheckpointClick = (checkpoint) => {

        const isSelected = selectedCheckpoints.includes(checkpoint);

        if (isSelected) {

            setSelectedCheckpoints(prevSelected =>
                prevSelected.filter(item => item !== checkpoint)
            );
        } else {

            setSelectedCheckpoints(prevSelected => [...prevSelected, checkpoint]);
        }
    }

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <div className="test-drive-container">
            <div className="upperblock">
                <div className="user-info-header">
                    Test Drive
                </div>
                <div className="mychecks">
                    <span
                        style={{fontSize: 14, color: "#646363"}}
                    >Test Drive Status</span>
                    {checkpoints.map((checkpoint, index) => (
                        <Checkpoint
                            key={index}
                            title={checkpoint}
                            selected={selectedCheckpoints.includes(checkpoint)}
                            onClick={() => handleCheckpointClick(checkpoint)}
                            isLast={index === checkpoints.length - 1}
                        />
                    ))}
                </div>
                <div className="test-drive-details-container">
                    <div className="test-drive-details-item">
                        <span>Location</span>
                        <select value={selectedValue} onChange={handleSelectChange}>
                            <option value="">Select</option>
                            <option value="option1">Chennai</option>
                        </select>
                    </div>
                    <div className="test-drive-details-item">
                        <span>Allocated Time Slot</span>
                        {Array.isArray(user.testDriveResponses) && user.testDriveResponses[0] !== undefined ?
                            <span>{user.testDriveResponses[0].allocatedTime}</span> : <span>Not Allocated</span>}
                    </div>
                </div>
            </div>
            <div className="prods-container">
                {products && products.map((product, index) => (
                    <div key={index} className="prods">
                        <div><img src={product.data[0].images.bike_images[0]} id='bikeimage' alt={`Product ${index}`}/>
                        </div>
                        <div className="test-drive-details">
                            <div>{product.data[0].brandName} ({product.data[0].batteryCapacity})</div>
                            <div>
                                <span className="user-info-key">Brand</span>
                                <span className="user-info-value">{product.data[0].modelName}</span>
                            </div>
                            <div>
                                <span className="user-info-key">Ex showroom price</span>
                                <span className="user-info-value">₹ {product.data[0].exShowroomPrice}/-</span>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default TestDrive1;