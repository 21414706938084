import React, {useEffect, useState} from 'react';
import "../../Css/TestDriveForm.css";
import apiService from "../ApiService";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useStore} from "../StoreContext";
import {useSelector} from "react-redux";
import {
    hideTestDriveForm
} from "../../Actions/ProductActions";
import {fetchBrandSummary} from "../../Actions/BrandSummaryAction";

function TestDriveForm() {
    const [states, setStates] = useState(null);
    const [cities, setCities] = useState(null);
    const [loadingState, setLoadingState] = useState(true);
    const [loadingCities, setLoadingCities] = useState(true);
    const [selectedState, setSelectedState] = useState('');
    const [modelName, setModelName] = useState();
    const store = useStore();
    const isFormVisible = useSelector(state => state.propertiesReducers.testDriveFormDisplay);
    const brands = useSelector(state => state.brandSummaryReducers.brands);

    function getFormData() {
        return {
            "state": null,
            "city": null,
            "address": null,
            "landmark": null,
            "pincode": null,
            "mobile": null,
            "modelNames": []
        }
    }

    const [formValue, setFormValue] = useState(getFormData())


    function hideForm() {
        console.log("hide form");
        store.dispatch(hideTestDriveForm());
    }

    useEffect(() => {
        setLoadingState(true);
        apiService.fetchAllStates()
            .then((fetchedData) => {
                console.log("state api call successful");
                setStates(fetchedData);
                setLoadingState(false);
            })
            .catch((error) => {
                console.error("Error occurred while calling get state api:", error);
                setLoadingState(false);
            });
        store.dispatch(fetchBrandSummary());
    }, [store]);

    useEffect(() => {

        apiService.fetchAllCities(selectedState)
            .then((fetchedData) => {
                setCities(fetchedData);
                setLoadingCities(false);
            })
            .catch((error) => {
                console.error("Error occurred while calling get state api:", error);
                setLoadingCities(false);
            })

    }, [selectedState]);

    const handleStateChange = (event) => {
        const selectedValue = event.target.value;
        const selectedState = states.find((state) => state.iso2 === selectedValue);
        if (selectedState) {
            setFormValue({...formValue, "state": selectedState.name});
        }
        console.log("selected state", selectedValue);
        setSelectedState(selectedValue);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        if (name === "modelNames") {
            setModelName(value);
        } else {
            setFormValue({...formValue, [name]: value});
        }
    }

    useEffect(() => {
        setFormValue({...formValue, modelNames: [modelName]});
    }, [modelName])


    const handleSubmit = (e) => {
        e.preventDefault();
        apiService.bookTestDrive(formValue)
            .then((fetchedData) => {
                console.log("book test drive api response", fetchedData.payload);
                toast.success(fetchedData.payload);
                hideForm();
                setFormValue(getFormData());
            })
            .catch((error) => {
                console.error("Error occurred while booking test drive:", error);
                toast.error(error);
            });
    }

    return (
        <div id="test-drive-form-container" style={{display: isFormVisible}}>
            <div className="test-drive-form-heading">Please fill correct details to continue the process...!!!</div>
            <img
                src="/images/close.png"
                alt="close"
                className="test-drive-close-icon"
                id="test-drive-close"
                onClick={hideForm}
            />
            <div>
                <img src="/images/bycicle.png"
                     alt="bike"
                     className="test-drive-bike-img"
                />
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <select
                        className="test-drive-form-input"
                        disabled={loadingState}
                        value={selectedState}
                        name="state"
                        onChange={handleStateChange}
                    >
                        <option value="">
                            State
                        </option>
                        {loadingState ? (
                            <option value="">Loading...</option>
                        ) : (
                            Array.isArray(states) &&
                            states.map((state, index) => (
                                <option
                                    key={index}
                                    value={state.iso2}
                                >
                                    {state.name}
                                </option>
                            ))
                        )}
                    </select>
                    <select className="test-drive-form-input"
                            disabled={loadingCities}
                            value={formValue.city}
                            onChange={handleChange}
                            name="city"
                    >
                        <option value="">
                            City
                        </option>
                        {loadingCities ? (
                            <option value="">Loading...</option>
                        ) : (
                            Array.isArray(cities) &&
                            cities.map((city, index) => (
                                <option
                                    key={index}
                                    value={city.name}
                                >
                                    {city.name}
                                </option>
                            ))
                        )}
                    </select>
                </div>
                <div>
                    <select
                        value={formValue.modelNames}
                        className="test-drive-form-input"
                        name="modelNames"
                        onChange={handleChange}>
                        <option value="">Select a bike model</option>
                        {Array.isArray(brands.electricBikes) && brands.electricBikes.map((bike, index) => (
                            bike.modelNames.map((modelName, modelIndex) => (
                                <option key={`bike-${index}-model-${modelIndex}`} value={modelName}>
                                    {modelName}
                                </option>
                            ))
                        ))}
                        {Array.isArray(brands.electricScooty) && brands.electricScooty.map((scooter, index) => (
                            scooter.modelNames.map((modelName, modelIndex) => (
                                <option key={`scooter-${index}-model-${modelIndex}`} value={modelName}>
                                    {modelName}
                                </option>
                            ))
                        ))}
                    </select>
                    <input
                        type="text"
                        placeholder="Address"
                        name="address"
                        value={formValue.address}
                        className="test-drive-form-input"
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <input
                        type="number"
                        placeholder="pincode"
                        className="test-drive-form-input"
                        name="pincode"
                        value={formValue.pincode}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        placeholder="Alternate Mobile No."
                        className="test-drive-form-input"
                        name="mobile"
                        value={formValue.mobile}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit" className="test-drive-form-btn">Continue to GET FREE TEST DRIVE</button>
            </form>
        </div>
    );
}

export default TestDriveForm;