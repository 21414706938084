import React from 'react';
import "../../Css/UserInfo.css";

function UserInfo({user}) {
    return (
        <div className="">
            <div className="user-info-header">My Profile</div>
            {user !== undefined && user !== null && <div className="user-info-container">
                <div className="user-profile-sec">
                    <div style={{marginBottom: 10}}>
                        <img src="/images/icons/user1.png"
                             alt="user-profile"
                             style={{width: 70, height: 70}}
                        />
                        <h3>{user.name}</h3>
                    </div>
                </div>
                <div className="user-info-sec">
                    <ul className="user-info-div">
                        <li className="user-info-heading">personal information</li>
                        <li>
                            <span className="user-info-key">name</span>
                            <span className="user-info-value">{user.name}</span></li>
                        <li>
                            <span className="user-info-key">gender</span>
                            <span className="user-info-value">{user.gender}</span>
                        </li>
                    </ul>
                    {/*<div style={{borderBottom: "1px solid lightgrey"}}></div>*/}
                    <ul className="user-info-div">
                        <li className="user-info-heading">security information</li>
                        <li>
                            <span className="user-info-key">email</span>
                            <div>
                                <span className="user-info-value">{user.email}</span>
                                {user.emailVerified ?
                                    <span
                                        className="verified-unverified-btn verified-btn">verified</span> :
                                    <span
                                        className="verified-unverified-btn unverified-btn">unverified</span>
                                }
                            </div>
                        </li>
                        <li>
                            <span className="user-info-key">mobile</span>
                            <div>
                                <span className="user-info-value">{user.mobile}</span>
                                {user.mobileVerified ?
                                    <span
                                        className="verified-unverified-btn verified-btn">verified</span> :
                                    <span
                                        className="verified-unverified-btn unverified-btn">unverified</span>}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>}
        </div>
    );
}

export default UserInfo;