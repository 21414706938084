import {combineReducers} from 'redux';
import productReducers from "./ProductReducers";
import propertiesReducers from "./PropertiesReducers";
import userReducers from "./UserReducers";
import brandSummaryReducers from './BrandSummaryReducer';
import testDriveReducers from "./TestDriveReducers";
import backOfficeReducers from "./BackOfficeReducers";
import articleReducers from "./ArticleReducers";
import homePageReducers from "./HomePageReducers";


const rootReducer = combineReducers({
    // user: userReducer,
    productReducer: productReducers,
    propertiesReducers: propertiesReducers,
    userReducers: userReducers,
    brandSummaryReducers: brandSummaryReducers,
    testDriveReducers: testDriveReducers,
    backOfficeReducers: backOfficeReducers,
    articleReducers: articleReducers,
    homePageReducers: homePageReducers,
});

export default rootReducer;
