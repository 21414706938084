import React, {useEffect} from 'react';
import {useStore} from "../StoreContext";
import {fetchReviews} from "../../Actions/ProductActions";
import ApiService from "../ApiService";
import {connect, useSelector} from "react-redux";

function Reviews({productName}) {
    const store = useStore();
    const reviews = useSelector(state => state.productReducer.reviews);

    useEffect(() => {
        ApiService.fetchReviews(productName).then(fetchedData => {
            console.log("fetched date of get reviews", fetchedData);
            store.dispatch(fetchReviews(fetchedData));
        }).catch(error => {
            console.log("Review page error", error);
        })
    }, []);

    return (
        <div className="product-review-container">
            {Array.isArray(reviews) && reviews.length > 0 ? (reviews.map(review => (
                <div className="product-review-cart">
                    <div style={{fontSize:18}}>{review.reviewerName}</div>
                    <div>{review.review}</div>
                </div>
            ))) : null}

        </div>
    );
}

const mapStateToProps = state => ({
    reviews: state.productReducer.reviews,
});

export default connect(mapStateToProps)(Reviews);