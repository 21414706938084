// import React, {useState} from "react";
// import "../Css/ForgotPassword.css"; // Import your CSS file'
// import "../Scss/signup.scss";
// import {ToastContainer, toast} from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
//
// const ForgotPassword = () => {
//     const [email, setEmail] = useState("");
//     const [message, setMessage] = useState("");
//
//     const forgotPasswordBtnStyle = {
//         paddingLeft: 15,
//         paddingRight: 20,
//     };
//
//     return (
//         <div className="root">
//             <div className="forgot-password-container">
//                 <h2>Forgot Password</h2>
//                 <p>{message}</p>
//                 <form className="forgot-password-form" onSubmit={handleForgotPassword}>
//                     <div className="field-wrap">
//                         <input
//                             type="email"
//                             name="email"
//                             placeholder="Enter your email"
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                         />
//                     </div>
//                     <button className="button button-block">Forgot Password</button>
//                 </form>
//             </div>
//         </div>
//     );
// };
//
// export default ForgotPassword;
