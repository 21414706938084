import React, {useState, useEffect} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import "../../Css/ProductCompare.css";
import ApiService from "../ApiService";
import {useSelector, useStore} from "react-redux";
import {fetchBrandSummary} from "../../Actions/BrandSummaryAction";
import Layout from "../Layout";


const ProductCompare = () => {
    const location = useLocation();
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [tabState, setTabState] = useState({
        bike: "tab-pc",
        scooter: "active-pc", // Corrected the key name to "signIn"
    });
    const [dataState, setDataState] = useState("");
    const [error, setError] = useState(null);
    const store = useStore();
    const brands = useSelector((state) => state.brandSummaryReducers.brands);
    const {fromProductPage, productDetails} = location.state || {};
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchRequest, setSearchRequest] = useState(null);
    const [modelIndex, setModelIndex] = useState();
    const model1 = searchParams.get("model1");
    const model2 = searchParams.get("model2");
    const model3 = searchParams.get("model3");

    const activeScooter = () => {
        setData1([]);
        setData2([]);
        setData3([]);

        setTabState({
            scooter: "active-pc",
            bike: "tab-pc",
        });
        setDataState("")
    };
    const activeBike = () => {
        setData1([]);
        setData2([]);
        setData3([]);
        setTabState({
            scooter: "tab-pc",
            bike: "active-pc",
        });
        setDataState("")
    };
    const dropdown = () => {
        store.dispatch(fetchBrandSummary());
    };

    useEffect(() => {
        if (fromProductPage) {
            setData1(productDetails)
            setDataState("hide")
            setSearchParams((params) => {
                params.set("model1", productDetails.modelName);
                return params;
            });
        }
    }, [fromProductPage, productDetails]);

    useEffect(() => {
        if (model1 !== null) {
            fetchData({'modelName': model1}, 1);
        }
        if (model2 !== null) {
            fetchData({'modelName': model2}, 2);
        }
        if (model3 !== null) {
            fetchData({'modelName': model3}, 3);
        }
    }, [model1, model2, model3]);

    useEffect(() => {
        if (searchRequest !== null)
            fetchData(searchRequest, modelIndex);
    }, [searchRequest, modelIndex]);

    const searchProduct = (e, index) => {
        const {value} = e.target;
        setSearchRequest({
            modelName: value
        });
        setModelIndex(index);
        // console.log("search api calling for value", value);
        // const searchRequest = {
        //     modelName: value,
        // };

        // eslint-disable-next-line default-case
        switch (index) {
            case 1:
                setSearchParams((params) => {
                    params.set("model1", value);
                    return params;
                });
                break;
            case 2:
                setSearchParams((params) => {
                    params.set("model2", value);
                    return params;
                });
                break;
            case 3:
                setSearchParams((params) => {
                    params.set("model3", value);
                    return params;
                });
                break;
        }
    };

    const fetchData = (request, index) => {
        ApiService.fetchProductData(request)
            .then((fetchedData) => {
                if (!fetchedData.payload || !fetchedData.payload.data) {
                    console.log("Error: No data found");
                    return;
                }
                if (index === 1) {
                    setData1(fetchedData.payload?.data[0]);
                }
                if (index === 2) {
                    setData2(fetchedData.payload?.data[0]);
                }
                if (index === 3) {
                    setData3(fetchedData.payload?.data[0]);
                }
                if (!fetchedData.payload.data) {
                    console.log("error");
                }
            })
            .catch((fetchError) => {
                setError(fetchError);
            });
    }

    return (
        <Layout
            title="Compare Electric Vehicles"
            description="Compare electric vehicles on electra wheeler product compare page"
            children={<div className="product-compare-page">
                <div className="top-banner-pd">
                    <div className="top-banner-text">
                        <div className="top-banner-subheading">
                            WHY STAY CONFUSED? CHOOSE THE BEST BETWEEN BESTS
                        </div>
                        <div className="top-banner-heading">COMPARE ELECTRIC VEHICLES</div>
                    </div>
                </div>
                <div className="bike-or-scooter">
                    <div className={tabState.scooter} onClick={activeScooter}>
                        Electric Scooter
                    </div>
                    <div className={tabState.bike} onClick={activeBike}>
                        Electric Bike
                    </div>
                </div>

                {error ? (
                    <div className="error-message">Error: {error.message}</div>
                ) : (
                    <div>
                        <div className="product-compare-container-1">
                            <div className="product-compare-column1">
                                <div className="product-compare-column-select">
                                    <select
                                        id="ev"
                                        className={"product-compare-select " + dataState}
                                        onClick={dropdown}
                                        value={model1}
                                        onChange={(e) => searchProduct(e, 1)}
                                    >
                                        <option>Select Model</option>
                                        {tabState.bike === "active-pc"
                                            ? Array.isArray(brands.electricBikes) && brands.electricBikes.map((brand) => (
                                            <optgroup key={brand.brandName} label={brand.brandName}>

                                                {brand.modelNames.map((modelName, index) => (
                                                    <option key={index} value={modelName}>
                                                        {brand.brandName + " " + modelName}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))
                                            : Array.isArray(brands.electricScooty) && brands.electricScooty.map((brand) => (
                                            <optgroup key={brand.brandName} label={brand.brandName}>

                                                {brand.modelNames.map((modelName, index) => (
                                                    <option key={index} value={modelName}>
                                                        {brand.brandName + " " + modelName}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </select>
                                    <div className={"pd-outer " + dataState}>
                                        {data1?.brandName ? (
                                            <div className="product-compare-product">
                                                <img
                                                    className="product-compare-logo"
                                                    src={data1.logo}
                                                    alt="logo"
                                                />
                                                <img
                                                    className="product-compare-bike"
                                                    src={data1.images.bike_images[0]}
                                                    alt="bike"
                                                />
                                                <div className="comparison-specs">
                                                    <div className="product-compare-name">
                                                        {data1.brandName + " " + data1.modelName}
                                                    </div>
                                                    <div className="pd-price">
                                                        &#8377; {data1.exShowroomPrice}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <img src="/images/icons/add-button.png" alt="add-product"
                                                 className="add-product-btn"/>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* copy */}
                            <div className="product-compare-column1">
                                <div className="product-compare-column-select">
                                    <select
                                        id="ev"
                                        className="product-compare-select"
                                        onClick={dropdown}
                                        value={model2}
                                        onChange={(e) => searchProduct(e, 2)}
                                    >
                                        <option>Select Model</option>
                                        {tabState.bike === "active-pc"
                                            ? Array.isArray(brands.electricBikes) && brands.electricBikes.map((brand) => (
                                            <optgroup key={brand.brandName} label={brand.brandName}>

                                                {brand.modelNames.map((modelName, index) => (
                                                    <option key={index} value={modelName}>
                                                        {brand.brandName + " " + modelName}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))
                                            : Array.isArray(brands.electricScooty) && brands.electricScooty.map((brand) => (
                                            <optgroup key={brand.brandName} label={brand.brandName}>
                                                {brand.modelNames.map((modelName, index) => (
                                                    <option key={index} value={modelName}>
                                                        {brand.brandName + " " + modelName}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </select>
                                    <div className="pd-outer">
                                        {data2.brandName ? (
                                            <div className="product-compare-product">
                                                <img
                                                    className="product-compare-logo"
                                                    src={data2.logo}
                                                    alt="logo"
                                                />
                                                <img
                                                    className="product-compare-bike"
                                                    src={data2.images.bike_images[0]}
                                                    alt="bike"
                                                />
                                                <div className="comparison-specs">
                                                    <div className="product-compare-name">
                                                        {data2.brandName + " " + data2.modelName}
                                                    </div>
                                                    <div className="pd-price">
                                                        &#8377; {data2.exShowroomPrice}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <img src="/images/icons/add-button.png" alt="add-product"
                                                 className="add-product-btn"/>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="product-compare-column1 mobile">
                                <div className="product-compare-column-select">
                                    <select
                                        id="ev"
                                        className="product-compare-select"
                                        onClick={dropdown}
                                        value={model3}
                                        onChange={(e) => searchProduct(e, 3)}
                                    >
                                        <option>Select Model</option>
                                        {tabState.bike === "active-pc"
                                            ? Array.isArray(brands.electricBikes) && brands.electricBikes.map((brand) => (
                                            <optgroup key={brand.brandName} label={brand.brandName}>
                                                {brand.modelNames.map((modelName, index) => (
                                                    <option key={index} value={modelName}>
                                                        {brand.brandName + " " + modelName}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))
                                            : Array.isArray(brands.electricScooty) && brands.electricScooty.map((brand) => (
                                            <optgroup key={brand.brandName} label={brand.brandName}>
                                                {brand.modelNames.map((modelName, index) => (
                                                    <option key={index} value={modelName}>
                                                        {brand.brandName + " " + modelName}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </select>
                                    <div className="pd-outer">
                                        {data3.brandName ? (
                                            <div className="product-compare-product">
                                                <img
                                                    className="product-compare-logo"
                                                    src={data3.logo}
                                                    alt="logo"
                                                />
                                                <img
                                                    className="product-compare-bike"
                                                    src={data3.images.bike_images[0]}
                                                    alt="bike"
                                                />
                                                <div className="comparison-specs">
                                                    <div className="product-compare-name">
                                                        {data3.brandName + " " + data3.modelName}
                                                    </div>
                                                    <div className="pd-price">
                                                        &#8377; {data3.exShowroomPrice}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <img src="/images/icons/add-button.png" alt="add-product"
                                                 className="add-product-btn"/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="head">
            <pre className="tex">
              {" "}
                ——— ——— ——— ——— ——— ——— ——— ——— ——— ——— ——— ——— ——— HIGHLIGHTS ———
              ——— ——— ——— ——— ——— ——— ——— ——— ——— ——— ——— ———{" "}
            </pre>
                        </div>
                        <div className="product-compare-lines">
                            <div className="product-compare-line"></div>
                            <div className="product-compare-line"></div>
                            <div className="product-compare-line"></div>
                            <div className="product-compare-line"></div>
                            <div className="product-compare-line"></div>
                        </div>

                        <div className="product-compare-container">
                            <div className="product-compare-heading-column-3">
                                <div className="product-compare-cell">
                                    <img
                                        src="/images/icons/lighting.png"
                                        alt="charging time"
                                        className="product-compare-image"
                                    />
                                    <div className="product-compare-heading-cell">ChargingTime</div>
                                </div>

                                <div className="product-compare-cell">
                                    <img
                                        src="/images/icons/location.png"
                                        alt="range"
                                        className="product-compare-image"
                                    />
                                    <div className="product-compare-heading-cell">Range</div>
                                </div>

                                <div className="product-compare-cell">
                                    <img
                                        src="/images/icons/weight.png"
                                        alt="weight"
                                        className="product-compare-image"
                                    />
                                    <div className="product-compare-heading-cell">Weight</div>
                                </div>
                                <div className="product-compare-cell">
                                    <img
                                        src="/images/icons/tools-and-utensils.png"
                                        alt="speed"
                                        className="product-compare-image"
                                    />
                                    <div className="product-compare-heading-cell">Speed</div>
                                </div>
                                <div className="product-compare-cell">
                                    <img
                                        src="/images/icons/engine.png"
                                        alt="power"
                                        className="product-compare-image"
                                    />
                                    <div className="product-compare-heading-cell">Motor Power</div>
                                </div>
                                <div className="product-compare-cell">
                                    <img
                                        src="/images/icons/car-battery.png"
                                        alt="battery"
                                        className="product-compare-image"
                                    />
                                    <div className="product-compare-heading-cell">
                                        Battery Capacity
                                    </div>
                                </div>
                            </div>
                            <div className="product-compare-columns-3">
                                {/* {combinedData.map((data) => ( */}
                                {data1 && <div className="product-compare-column-3">
                                    <div className="product-compare-cell">
                                        {data1.fastChargingSupport}
                                    </div>
                                    <div className="product-compare-cell">{data1.trueRange}</div>
                                    <div className="product-compare-cell">{data1.kerbWeight}</div>
                                    <div className="product-compare-cell">{data1.topSpeed}</div>
                                    <div className="product-compare-cell">
                                        {data1.batteryCapacity}
                                    </div>
                                </div>}
                                {data2 && <div className="product-compare-column-3">
                                    <div className="product-compare-cell">
                                        {data2.fastChargingSupport}
                                    </div>
                                    <div className="product-compare-cell">{data2.trueRange}</div>
                                    <div className="product-compare-cell">{data2.kerbWeight}</div>
                                    <div className="product-compare-cell">{data2.topSpeed}</div>
                                    <div className="product-compare-cell">
                                        {data2.batteryCapacity}
                                    </div>
                                </div>}

                                {data3 && <div className="product-compare-column-3 mobile">
                                    <div className="product-compare-cell">
                                        {data3.fastChargingSupport}
                                    </div>
                                    <div className="product-compare-cell">{data3.trueRange}</div>
                                    <div className="product-compare-cell">{data3.kerbWeight}</div>
                                    <div className="product-compare-cell">{data3.topSpeed}</div>
                                    <div className="product-compare-cell">
                                        {data3.batteryCapacity}
                                    </div>
                                </div>}
                                {/* ))} */}
                            </div>
                        </div>
                        <div className="head">
            <pre className="tex1">
              ——— ——— ——— ——— ——— ——— ——— ——— ——— ——— ——— ——— ——— SPECIFICATIONS
              ——— ———— ——— ——— ——— ——— ——— ——— ——— ——— ——— ——— ———
            </pre>
                        </div>
                        <div className="product-compare-subheading">General</div>
                        <div className="product-compare-container-3">
                            <div className="product-compare-heading-column-3">
                                <div className="product-compare-heading-cell">Brand</div>
                                <div className="product-compare-heading-cell">Model Name</div>
                                <div className="product-compare-heading-cell">Model Year</div>
                                <div className="product-compare-heading-cell">Brand Color</div>
                                <div className="product-compare-heading-cell">Type</div>
                                <div className="product-compare-heading-cell">Color</div>
                                <div className="product-compare-heading-cell">Motor Type</div>
                                <div className="product-compare-heading-cell">Wheel Material</div>
                                <div className="product-compare-heading-cell">
                                    Number of Wheels
                                </div>
                                <div className="product-compare-heading-cell">
                                    Seating Capacity
                                </div>
                                <div className="product-compare-heading-cell">Maximum Torque</div>
                                <div className="product-compare-heading-cell">Maximum Speed</div>
                                <div className="product-compare-heading-cell">Range</div>
                                <div className="product-compare-heading-cell">
                                    Console Features
                                </div>
                                <div className="product-compare-heading-cell">Tail Lamp</div>
                                <div className="product-compare-heading-cell">Turn Light</div>
                            </div>
                            <div className="product-compare-columns-3">
                                {data1 && <div className="product-compare-column-3">
                                    <div className="product-compare-cell-3">{data1.brandName}</div>
                                    <div className="product-compare-cell-3">{data1.modelName}</div>
                                    <div className="product-compare-cell-3">{data1.modelYear}</div>
                                    <div className="product-compare-cell-3">
                                        {data1.defaultColor}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data1.productCategory}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data1.defaultColor}
                                    </div>
                                    <div className="product-compare-cell-3">{data1.motorType}</div>
                                    <div className="product-compare-cell-3">{data1.wheelType}</div>
                                    <div className="product-compare-cell-3">
                                        {data1.numberOfWheels}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data1.loadingCapacity}
                                    </div>
                                    <div className="product-compare-cell-3">{data1.peakTorque}</div>
                                    <div className="product-compare-cell-3">{data1.topSpeed}</div>
                                    <div className="product-compare-cell-3">{data1.trueRange}</div>
                                    <div className="product-compare-cell-3">
                                        {data1.consoleFeatures}
                                    </div>
                                    <div className="product-compare-cell-3">{data1.tailLight}</div>
                                    <div className="product-compare-cell-3">{data1.headLight}</div>
                                </div>}

                                {data2 && <div className="product-compare-column-3">
                                    <div className="product-compare-cell-3">{data2.brandName}</div>
                                    <div className="product-compare-cell-3">{data2.modelName}</div>
                                    <div className="product-compare-cell-3">{data2.modelYear}</div>
                                    <div className="product-compare-cell-3">
                                        {data2.defaultColor}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data2.productCategory}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data2.defaultColor}
                                    </div>
                                    <div className="product-compare-cell-3">{data2.motorType}</div>
                                    <div className="product-compare-cell-3">{data2.wheelType}</div>
                                    <div className="product-compare-cell-3">
                                        {data2.numberOfWheels}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data2.loadingCapacity}
                                    </div>
                                    <div className="product-compare-cell-3">{data2.peakTorque}</div>
                                    <div className="product-compare-cell-3">{data2.topSpeed}</div>
                                    <div className="product-compare-cell-3">{data2.trueRange}</div>
                                    <div className="product-compare-cell-3">
                                        {data2.consoleFeatures}
                                    </div>
                                    <div className="product-compare-cell-3">{data2.tailLight}</div>
                                    <div className="product-compare-cell-3">{data2.headLight}</div>
                                </div>}

                                {data3 && <div className="product-compare-column-3 mobile">
                                    <div className="product-compare-cell-3">{data3.brandName}</div>
                                    <div className="product-compare-cell-3">{data3.modelName}</div>
                                    <div className="product-compare-cell-3">{data3.modelYear}</div>
                                    <div className="product-compare-cell-3">
                                        {data3.defaultColor}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data3.productCategory}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data3.defaultColor}
                                    </div>
                                    <div className="product-compare-cell-3">{data3.motorType}</div>
                                    <div className="product-compare-cell-3">{data3.wheelType}</div>
                                    <div className="product-compare-cell-3">
                                        {data3.numberOfWheels}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data3.loadingCapacity}
                                    </div>
                                    <div className="product-compare-cell-3">{data3.peakTorque}</div>
                                    <div className="product-compare-cell-3">{data3.topSpeed}</div>
                                    <div className="product-compare-cell-3">{data3.trueRange}</div>
                                    <div className="product-compare-cell-3">
                                        {data3.consoleFeatures}
                                    </div>
                                    <div className="product-compare-cell-3">{data3.tailLight}</div>
                                    <div className="product-compare-cell-3">{data3.headLight}</div>
                                </div>}
                            </div>
                        </div>
                        <div className="product-compare-subheading">Power Features</div>
                        <div className="product-compare-container-3">
                            <div className="product-compare-heading-column-3">
                                <div className="product-compare-heading-cell">
                                    Number of Batteries
                                </div>
                                <div className="product-compare-heading-cell">Power Source</div>
                                <div className="product-compare-heading-cell">Battery Size</div>
                                <div className="product-compare-heading-cell">Battery Type</div>
                                <div className="product-compare-heading-cell">
                                    Battery Capacity
                                </div>
                                <div className="product-compare-heading-cell">Motor Power</div>
                                <div className="product-compare-heading-cell">Charging Time</div>
                            </div>
                            <div className="product-compare-columns-3">
                                {data1 && <div className="product-compare-column-3">
                                    <div className="product-compare-cell-3">
                                        {data1.numberOfBatteries}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data1.powerSource}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data1.batteryCapacity}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data1.batteryType}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data1.batteryCapacity}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data1.motorPeakPowerCapacity}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data1.fastChargerChargingTime}
                                    </div>
                                </div>}

                                {data2 && <div className="product-compare-column-3">
                                    <div className="product-compare-cell-3">
                                        {data2.numberOfBatteries}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data2.powerSource}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data2.batteryCapacity}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data2.batteryType}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data2.batteryCapacity}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data2.motorPeakPowerCapacity}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data2.fastChargerChargingTime}
                                    </div>
                                </div>}

                                {data3 && <div className="product-compare-column-3 mobile">
                                    <div className="product-compare-cell-3">
                                        {data3.numberOfBatteries}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data3.powerSource}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data3.batteryCapacity}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data3.batteryType}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data3.batteryCapacity}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data3.motorPeakPowerCapacity}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data3.fastChargerChargingTime}
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="product-compare-subheading">
                            Wheel And Tire Features
                        </div>
                        <div className="product-compare-container-3">
                            <div className="product-compare-heading-column-3">
                                <div className="product-compare-heading-cell">Tyre Type</div>
                                <div className="product-compare-heading-cell">Wheel Type</div>
                                <div className="product-compare-heading-cell">
                                    Tire Size (Front)
                                </div>
                                <div className="product-compare-heading-cell">
                                    Tire Size (Rear)
                                </div>
                            </div>

                            <div className="product-compare-columns-3">
                                {data1 && <div className="product-compare-column-3">
                                    <div className="product-compare-cell-3">{data1.tyreType}</div>
                                    <div className="product-compare-cell-3">{data1.wheelType}</div>
                                    <div className="product-compare-cell-3">
                                        {data1.frontTyreSize}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data1.rearTyreSize}
                                    </div>
                                </div>}

                                {data2 && <div className="product-compare-column-3">
                                    <div className="product-compare-cell-3">{data2.tyreType}</div>
                                    <div className="product-compare-cell-3">{data2.wheelType}</div>
                                    <div className="product-compare-cell-3">
                                        {data2.frontTyreSize}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data2.rearTyreSize}
                                    </div>
                                </div>}

                                {data3 && <div className="product-compare-column-3 mobile">
                                    <div className="product-compare-cell-3">{data3.tyreType}</div>
                                    <div className="product-compare-cell-3">{data3.wheelType}</div>
                                    <div className="product-compare-cell-3">
                                        {data3.frontTyreSize}
                                    </div>
                                    <div className="product-compare-cell-3">
                                        {data3.rearTyreSize}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                )}
            </div>}
        >

        </Layout>
    );
};
export default ProductCompare;
