// StoreContext.js
import { createContext, useContext } from 'react';
import store from "./Utils/Store";

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export const useStore = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return context;
};