import {
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_FAILED,
    UPLOAD_DOCUMENT_IN_PROGRESS,
    RESET_SUCCESS_MSG,
    RESET_FAILURE_MSG
} from "../Actions/BackOfficeActions";

const initialState = {
    uploadDocumentInProgress: false,
    successMessage: null,
    failureMessage: null
}

const BackOfficeReducers = (state = initialState, action) => {
    console.log("backoffice reducer called", state);
    switch (action.type) {
        case UPLOAD_DOCUMENT_IN_PROGRESS:
            return {
                ...state,
                uploadDocumentInProgress: true
            }
        case UPLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                successMessage: action.payload,
                uploadDocumentInProgress: false
            }
        case UPLOAD_DOCUMENT_FAILED:
            return {
                ...state,
                failureMessage: action.payload,
                uploadDocumentInProgress: false
            }
        case RESET_SUCCESS_MSG:
            return {
                ...state,
                successMessage: null
            }
        case RESET_FAILURE_MSG:
            return {
                ...state,
                failureMessage: null
            }
        default:
            return state;
    }
}

export default BackOfficeReducers;