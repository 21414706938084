import React, {useState} from 'react';
import '../../Css/EmiCalculator.css';
import {useSelector} from "react-redux";
import {useStore} from "../StoreContext";
import {showEmiCalculatorForm} from "../../Actions/PropertyActions"; // Import the CSS file for styling

const EmiCalculator = () => {
    const showEmiCalculator = useSelector(state => state.propertiesReducers.showEmiCalculator);
    const [principal, setPrincipal] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [tenure, setTenure] = useState('');
    const [emi, setEmi] = useState(null);
    const store = useStore();

    const calculateEmi = () => {
        const principalAmount = parseFloat(principal);
        const monthlyInterestRate = parseFloat(interestRate) / 1200; // Convert annual interest rate to monthly
        const numberOfPayments = parseFloat(tenure) * 12;

        const emiValue =
            (principalAmount * monthlyInterestRate) /
            (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

        setEmi(emiValue.toFixed(2));
    };

    function handleBackgroundClick() {
        store.dispatch(showEmiCalculatorForm(false));
        setTenure('');
        setPrincipal('');
        setInterestRate('');
        setEmi('');
    }

    return (
        <div>
            {showEmiCalculator && <div className="form-container" onClick={handleBackgroundClick}>
                <div className="emi-container">
                    <h1>Calculate EMI</h1>
                    <div className="emi-form">
                        <div className="input-group">
                            <label className="emi-cal-label">Loan Amount (&#x20b9;)</label>
                            <input
                                type="number"
                                className="emi-cal-input"
                                value={principal}
                                onChange={(e) => setPrincipal(e.target.value)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                        </div>
                        <div className="input-group">
                            <label className="emi-cal-label">Annual Interest Rate (%)</label>
                            <input
                                type="number"
                                className="emi-cal-input"
                                value={interestRate}
                                onChange={(e) => setInterestRate(e.target.value)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                        </div>
                        <div className="input-group">
                            <label className="emi-cal-label">Tenure (years)</label>
                            <input
                                className="emi-cal-input"
                                type="number"
                                value={tenure}
                                onChange={(e) => setTenure(e.target.value)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                        </div>
                        <button className="emi-cal-btn"
                            onClick={(e) => {
                            e.stopPropagation();
                            calculateEmi()
                        }}
                        >Calculate EMI
                        </button>
                        {/*<div>Clear All</div>*/}
                    </div>
                    {emi && (
                        <div className="result">
                            <h3>EMI: &#x20b9;{emi}</h3>
                        </div>
                    )}
                </div>
            </div>}
        </div>
    );
};

export default EmiCalculator;
