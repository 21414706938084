import React, {useEffect, useState} from 'react';
import '../../Css/ProductArticle.css'
import {useNavigate} from "react-router-dom";
import {CiCalendar} from "react-icons/ci";
import {fetchArticles} from "../../Actions/ArticleActions";
import store from "../Utils/Store";
import {connect, useSelector} from "react-redux";
import Layout from "../Layout";

function Articles(props) {
    const articles = useSelector(state => state.articleReducers.articles);
    const [searchRequest, setSearchRequest] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const articlesPerPage = 24;
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        store.dispatch(fetchArticles(searchRequest));
    }, [searchRequest]); // Add searchRequest as dependency to rerender on search

    const handleDetails = (article) => {
        navigate(`/blog/${article.headline}`);
    }

    // Logic to paginate articles
    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = articles ? articles.slice(indexOfFirstArticle, indexOfLastArticle) : [];

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <Layout
            title="Blogs And Articles"
            description="Read about electric vehicles on electra wheeler's blog page"
            children={<div className="articles-container">
                <div className="article-search-bar-container">
                    <input
                        type="text"
                        placeholder="Search Product Article"
                        className="article-search-bar"
                        onChange={(e) => setSearchRequest(e.target.value)}
                    />
                </div>
                <div className="article-main-container">
                    <div className="article-cart-section">
                        {currentArticles.length > 0 ? currentArticles.map((article, index) => (
                            <div className="article-cart" key={index}
                                 onClick={() => handleDetails(article)}
                            >
                                <div>
                                    <img className="article-cart-img" src={article.content[0].images[0]}
                                         alt={`Article ${index + 1}`}/>
                                    <div style={{textAlign: "left", padding: 10}}>
                                        <div className="timestamp">
                                            <CiCalendar/>
                                            <span className="timestamp-text">{article.blogPageCreatedOn}</span>
                                        </div>
                                        <div style={{
                                            fontSize: 14,
                                            marginBottom: 5
                                        }}>{article.content[0].headers[0]}</div>
                                        <p className="article-cart-para">{article.content[0].paragraphs[0]}</p>
                                    </div>
                                </div>
                            </div>)) : (<div className="article-cart">Fetching Articles...</div>)}
                    </div>
                    <div className="article-side-cart1">
                        <h3 style={{color: "green"}}>Recent Blogs</h3>
                        {articles !== null && Array.isArray(articles) ? articles.slice(0, 10).map((article, index) => (
                            <div className="article-side-cart1-heading" key={index}
                                 onClick={() => handleDetails(article)}
                            >{article.content[0].headers[0]}</div>)) : null}
                    </div>
                </div>
                {/* Pagination */}
                <div className="pagination">
                    <button onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
                            className="pagination-btn">Prev
                    </button>
                    {Array.from({length: Math.ceil(articles ? articles.length / articlesPerPage : 0)}, (_, i) => (i < 5 &&
                        <button
                            key={i}
                            onClick={() => paginate(i + 1)}
                            className="pagination-btn"
                            style={{
                                backgroundColor: currentPage === i + 1 ? "#3bc12f" : "inherit",
                                color: currentPage === i + 1 ? "white" : "black",
                                paddingLeft: 10,
                                paddingRight: 10,
                                border: "none",
                            }}
                        >
                            {i + 1}
                        </button>))}
                    <button
                        className="pagination-btn"
                        onClick={() => setCurrentPage(currentPage === Math.ceil(articles.length / articlesPerPage) ? Math.ceil(articles.length / articlesPerPage) : currentPage + 1)}>Next
                    </button>
                </div>
            </div>}
        >

        </Layout>
    );
}

const mapStateToProps = state => ({
    articles: state.articleReducers.articles
})

export default connect(mapStateToProps)(Articles);
