// FAQ.js

import React, {useEffect, useState} from 'react';
import '../../Css/faq.css';
import {useStore} from "../StoreContext";
import {fetchFaqs} from "../../Actions/FaqActions";
import {useSelector} from "react-redux"; // Import the CSS file for styling

const FAQ = ({referenceId, pageName}) => {
    // State to manage which question is currently expanded
    const [expandedIndex, setExpandedIndex] = useState(null);
    const store = useStore();
    const faqs = useSelector(state => state.homePageReducers.faqs);

    useEffect(() => {
        store.dispatch(fetchFaqs(referenceId, pageName));
    }, [referenceId, pageName]);

    const toggleQuestion = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            <h1 className="faq-heading">Frequently Asked Questions (FAQs)</h1>
            <div className="faq-list">
                {faqs && Array.isArray(faqs) && faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <div
                            className={`faq-question ${expandedIndex === index ? 'expanded' : ''}`}
                            onClick={() => toggleQuestion(index)}
                        >
                            {faq.question}
                            <div className="arrow-icon">{expandedIndex === index ? '-' : '+'}</div>
                        </div>
                        {expandedIndex === index && (
                            <div className="faq-answer">
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
