import React, {useEffect, useState} from "react";
import "../../Scss/signup.scss";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ApiUrls} from "../Utils/ConfigUtil";
import {IoCloseOutline} from "react-icons/io5";
import {fetchUser, sendOtpOnMobile, updateLoginUserToken, verifyOtp} from "../../Actions/UserActions";
import store from "../Utils/Store";
import {useSelector} from "react-redux";
import {hideLoginForm} from "../../Actions/ProductActions";


const SignIn = () => {
        const [email, setEmail] = useState("");
        const [otp, setOtp] = useState("");
        const navigate = useNavigate();
        const showLoginForm = useSelector(state => state.propertiesReducers.showLoginForm);
        const userMobile = useSelector(state => state.userReducers.userMobile);
        console.log("show login form", showLoginForm);

        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        const [formData, setFormData] = useState(getInitialFormData());

        const [tabState, setTabState] = useState({
            signUp: "tab",
            signIn: "tab active",
            forgotPassword: "tab",
            response: null,
        });

        const [signInData, setSignInData] = useState({
            username: "",
            password: "",
        });

        useEffect(() => {
            setSignInData({
                username: "",
                password: "",
            });
            setEmail("");
            setFormData(getInitialFormData());
        }, [tabState]);

        function getInitialFormData() {
            return {
                email: "",
                mobile: "",
                name: "",
                password: "",
                gender: null
            };
        }

        const activateTab = (activeTab) => {
            const tabStates = {
                signUp: "tab",
                signIn: "tab",
                forgotPassword: "tab",
            };
            tabStates[activeTab] = "tab active";
            setTabState(tabStates);
            document.getElementById("signup").style.display = activeTab === "signUp" ? "block" : "none";
            document.getElementById("forgot-password").style.display = activeTab === "forgotPassword" ? "block" : "none";
            document.getElementById("active-tab").style.display = activeTab === "forgotPassword" ? "none" : "block";
            document.getElementById("active-tab").style.display = activeTab === "sendOtp" ? "none" : "block";
            document.getElementById("login").style.display = activeTab === "signIn" ? "block" : "none";
            document.getElementById("submit-otp").style.display = activeTab === "sendOtp" ? "block" : "none";
        };

        const activeSignIn = () => {
            activateTab("signIn");
        };

        const activeSignUp = () => {
            activateTab("signUp");
        };

        const activeForgotPassword = () => {
            activateTab("forgotPassword");
        };


        const handleChange = (e) => {
            const {name, value} = e.target;
            setFormData({...formData, [name]: value});
        };

        const handleSubmit = (e) => {
            e.preventDefault();

            fetch(`${ApiUrls.baseUrl}/api/v0/signUp/user`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error(`Signup failed: ${response.status}`);
                    }
                })
                .then((data) => {
                    console.log("Signup successful:", data);
                    toast.success("Signup Success");
                    activeSignIn();
                    store.dispatch(hideLoginForm());
                    setFormData(getInitialFormData());
                })
                .catch((error) => {
                    console.error("Error:", error);
                    toast.error("Signup failed!!");
                });
        };

        const handleForgotPassword = async (e) => {
            e.preventDefault();

            console.log(`Sending reset password link to ${email}`);
            const apiUrl = `${ApiUrls.baseUrl}/api/v0/login/forget-password/` + email;
            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        accept: '*/*',
                    },
                });
                if (response.ok) {
                    await response.json();
                    activeSignIn();
                    toast.success('Reset password email has been send to email');
                } else {
                    console.error('Error:', response.status);
                    toast.error("Something went wrong!")
                }
            } catch (error) {
                console.error('Error:', error);
            }

        };

        const handleChangeSignIn = (e) => {
            const {name, value} = e.target; // Changed "username" to "name"
            setSignInData({...signInData, [name]: value});
        };

        const handleSubmitSignIn = (e) => {
            e.preventDefault();

            const urlEncodedData = new URLSearchParams();
            urlEncodedData.append("username", signInData.username);
            urlEncodedData.append("password", signInData.password);

            fetch(`${ApiUrls.baseUrl}/api/token`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: urlEncodedData.toString(), // Converted to a string
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error(`Sign-in failed: ${response.status}`);
                    }
                })
                .then((data) => {
                    console.log("Sign-in successful:", data);
                    // updateToken(data.access_token);
                    store.dispatch(updateLoginUserToken(data.access_token));
                    setFormData(getInitialFormData);
                    store.dispatch(fetchUser(data.access_token));
                    store.dispatch(hideLoginForm());
                    navigate("/profile");
                })
                .catch((error) => {
                    console.error("Error:", error);
                    toast.error("Invalid username or password!")
                });
        };
        const handleBackgroundClick = () => {
            store.dispatch(hideLoginForm());
        };

        function sendOtp() {
            console.log("otp sending for mobile", signInData.username);
            store.dispatch(sendOtpOnMobile(signInData.username));
            activeOtpPage();
        }

        const activeOtpPage = () => {
            activateTab("sendOtp");
        };

        const handleSubmitOtp = (e) => {
            e.preventDefault();
            store.dispatch(verifyOtp(userMobile, otp));
            // navigate("/profile");
        }

        return (
            <div>
                {showLoginForm && (<div className="form-container" onClick={handleBackgroundClick}>
                    <div id="login-page-container" onClick={(e) => e.stopPropagation()}>
                        <div className="form">
                            <IoCloseOutline
                                style={{color: "black", fontSize: 25, position: "absolute", top: "2%", right: "2%"}}
                                onClick={handleBackgroundClick}
                            />
                            <ol className="tab-group" id="active-tab">
                                <li className={tabState.signUp} onClick={(e) => {
                                    e.stopPropagation();
                                    activeSignUp();
                                }}>
                                    <Link to="#">Sign Up</Link>
                                </li>
                                <li className={tabState.signIn} onClick={(e) => {
                                    e.stopPropagation();
                                    activeSignIn();
                                }}>
                                    <Link to="#">Log In</Link>
                                </li>
                            </ol>
                            <div className="tab-content">
                                <div id="login">
                                    <h1 className="form-heading">Welcome Back!</h1>
                                    <form onSubmit={handleSubmitSignIn} onClick={(e) => e.stopPropagation()}>
                                        <div className="field-wrap">
                                            <input
                                                type="text"
                                                name="username"
                                                placeholder="Mobile"
                                                value={signInData.username}
                                                autoComplete="username"
                                                onChange={handleChangeSignIn}
                                                required={true}
                                            />
                                        </div>

                                        <div className="field-wrap">
                                            <input
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                value={signInData.password}
                                                autoComplete="current-password"
                                                onChange={handleChangeSignIn}
                                                required={true}
                                            />
                                        </div>
                                        <span
                                            onClick={sendOtp}
                                            style={{
                                                fontSize: 14,
                                                color: "#4747e9",
                                                cursor: "pointer",
                                                marginTop: 10,
                                                marginLeft: 7,
                                            }}>
                                            Login With Otp
                                        </span>
                                        <li className="forgot"
                                            onClick={activeForgotPassword}
                                            style={{
                                                listStyle: "none",
                                                fontSize: 14,
                                                marginBottom: 7,
                                                color: "#4747e9",
                                                cursor: "pointer"
                                            }}
                                        >
                                            Forgot Password?
                                        </li>
                                        <button type="submit" className="button button-block">
                                            Log In
                                        </button>
                                    </form>
                                </div>
                                <div id="signup">
                                    <h1 className="form-heading">Sign Up for Free</h1>
                                    <form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
                                        <div className="top-row">
                                            <div className="field-wrap">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required={true}
                                                />
                                            </div>

                                            <div className="field-wrap">
                                                <input
                                                    type="text"
                                                    name="mobile"
                                                    placeholder="Mobile"
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                    required={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="field-wrap">
                                            <input
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <select className="field-wrap-select"
                                                value={formData.gender}
                                                onChange={handleChange}
                                                required={true}
                                        >
                                            <option>Gender</option>
                                            <option>Male</option>
                                            <option>Female</option>
                                        </select>
                                        <div className="field-wrap">
                                            <input
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                required={true}
                                            />
                                        </div>

                                        <button type="submit" className="button button-block">
                                            Create Account
                                        </button>
                                    </form>
                                </div>
                                <div id="forgot-password">
                                    <h3 className="form-heading" style={{marginBottom: 20}}>Forgot Password</h3>
                                    <form onSubmit={handleForgotPassword}
                                          onClick={(e) => e.stopPropagation()}>
                                        <div className="field-wrap">
                                            <input
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                value={email}
                                                required={true}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <button type="submit" className="button button-block"
                                                style={{marginTop: -20}}
                                        >
                                            Reset Password
                                        </button>
                                        <div
                                            onClick={activeSignIn}
                                            style=
                                                {
                                                    {
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        marginTop: 10,
                                                        marginBottom: -15,
                                                        paddingRight: 10,
                                                        color: "#2828ca",
                                                        cursor: "pointer"
                                                    }}>
                                            Back
                                        </div>
                                    </form>
                                </div>
                                <div id="submit-otp">
                                    <h3 className="form-heading" style={{marginBottom: 20, fontFamily: "sans-serif"}}>Enter
                                        Your Otp</h3>
                                    <form onSubmit={handleSubmitOtp}
                                          onClick={(e) => e.stopPropagation()}>
                                        <div className="field-wrap">
                                            <input
                                                type="text"
                                                name="otp"
                                                placeholder="Otp"
                                                value={otp}
                                                required={true}
                                                onChange={(e) => setOtp(e.target.value)}
                                            />
                                        </div>
                                        <button type="submit" className="button button-block"
                                                style={{marginTop: -20}}
                                        >
                                            Submit
                                        </button>
                                        <div
                                            onClick={activeSignIn}
                                            style=
                                                {
                                                    {
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        marginTop: 10,
                                                        marginBottom: -15,
                                                        paddingRight: 10,
                                                        color: "#2828ca",
                                                        cursor: "pointer"
                                                    }}>
                                            Back
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>

        );
    }
;

export default SignIn;
