import React from 'react';
import {Helmet} from 'react-helmet';

function Layout({title, description, children}) {
    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
                {/* Add more metadata tags as needed */}
            </Helmet>
            {children}
        </div>
    );
}

export default Layout;
