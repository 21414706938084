import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import Specification from "./Specification";

function ProductSpecifications(props) {
    const product = useSelector(state => state.productReducer.product);

    const sectionTitle1 = 'Product Information';
    const sectionTitle2 = 'General Features';
    const sectionTitle3 = 'Battery';
    const sectionTitle4 = 'Charger & Charging';
    const sectionTitle5 = 'Motor';
    const sectionTitle6 = 'Display';
    const sectionTitle7 = 'Connectivity';
    const sectionTitle8 = 'Brake & Wheels';
    const sectionTitle9 = 'Suspension & Frame';
    const sectionTitle10 = 'Dimension';
    const sectionTitle11 = 'Peripherals & Convenience';
    const sectionTitle12 = 'Warranty';


    const [specification1, setSpecification1] = useState([
        { label: 'Brand', value: '' },
        { label: 'Model Name', value: '' },
        { label: 'Year', value: '' },
        { label: 'Color', value: '' },
        { label: 'Type', value: '' },
    ]);
    const [specification2, setSpecification2] = useState([
        { label: 'Colour', value: '' },
        { label: 'Driving mode', value: '' },
        { label: 'Reverse Mode', value: '' },
        { label: 'Idc Range', value: '' },
        { label: 'True Range', value: '' },
        { label: 'Top Speed', value: '' },
        { label: 'Acceleration', value: '' },
        { label: 'Boot Space', value: '' },
        { label: 'Fast Charging', value: '' },
    ]);
    const [specification3, setSpecification3] = useState([
        { label: 'Capacity', value: '' },
        { label: 'Battery Type', value: '' },
        { label: 'Number Of Battery', value: '' },
        { label: 'Water & Dust Safety', value: '' },
    ]);

    const [specification4, setSpecification4] = useState([
        { label: 'Home / Portable charger', value: '' },
        { label: 'Fast Charger', value: '' },
    ]);

    const [specification5, setSpecification5] = useState([
        { label: 'Motor Type', value: '' },
        { label: 'Power / Capacity', value: '' },
        { label: 'Peak Torque', value: '' },
    ]);

    const [specification6, setSpecification6] = useState([
        { label: 'Screen Size', value: '' },
        { label: 'Screen Resolution', value: '' },
        { label: 'Navigation system', value: '' },
        { label: 'Ram', value: '' },
        { label: 'Storage', value: '' },
        { label: 'Reverse', value: 'NA' },
        { label: 'Cruise control', value: '' },
        { label: 'OTA updates', value: '' },
        { label: 'Geo Fencing', value: '' },
        { label: 'Ride status', value: '' },
        { label: 'Remote Boot Unlock', value: '' },
        { label: 'Music', value: '' },
        { label: 'Predictive Maintenance', value: '' },
    ]);

    const [specification7, setSpecification7] = useState([
        { label: 'Bluetooth', value: '' },
        { label: 'Internet', value: '' },
    ]);

    const [specification8, setSpecification8] = useState([
        { label: 'Front Brake Type', value: '' },
        { label: 'Rear Brake Type', value: '' },
        { label: 'ABS / CBS', value: '' },
        { label: 'Wheel Type', value: '' },
        { label: 'Front Wheel Size', value: 'NA' },
        { label: 'Rear Wheel Size', value: 'NA' },
        { label: 'Front Tyre Size', value: '' },
        { label: 'Rear Tyre Size', value: '' },
    ]);

    const [specification9, setSpecification9] = useState([
        { label: 'Front suspension', value: '' },
        { label: 'Rear Suspension', value: '' },
        { label: 'Frame Type', value: '' },
        { label: 'Frame Material', value: '' },
        { label: 'Loading Capacity', value: '' },
    ]);

    const [specification10, setSpecification10] = useState([
        { label: 'Kerb Weight', value: '' },
        { label: 'Overall Length', value: '' },
        { label: 'Overall Wading', value: '' },
        { label: 'Overall Height', value: '' },
        { label: 'Wheel Base', value: '' },
        { label: 'Seat Height', value: '' },
        { label: 'Seat Length', value: '' },
        { label: 'Ground Clearance', value: '' },
    ]);

    const [specification11, setSpecification11] = useState([
        { label: 'Headlight', value: '' },
        { label: 'Taillights', value: '' },
        { label: 'Indicator', value: '' },
        { label: 'Side Stand Motor Cut-off', value: '' },
    ]);

    const [specification12, setSpecification12] = useState([
        { label: 'Battery Warranty', value: '' },
        { label: 'Vehicle Warranty', value: '' },
        { label: 'Covered In Warranty', value: '' },
        { label: 'Not Covered In Warranty', value: '' },
    ]);


    useEffect(() => {
        setSpecification1([
            {label: 'Brand', value: product.brandName},
            {label: 'Model Name', value: product.modelName},
            {label: 'Year', value: product.modelYear},
            {label: 'Color', value: product.defaultColor},
            {label: 'Type', value: product.productCategory},
        ]);
        setSpecification2([
            {label: 'Colour', value: product.defaultColor},
            {label: 'Driving mode', value: product.drivingMode},
            {label: 'Reverse Mode', value: product.reverseMode},
            {label: 'Idc Range', value: product.idcRange},
            {label: 'True Range', value: product.trueRange},
            {label: 'Top Speed', value: product.topSpeed},
            {label: 'Acceleration', value: product.acceleration},
            {label: 'Boot Space', value: product.bootSpace},
            {label: 'Fast Charging', value: product.fastChargingSupport}
        ]);
        setSpecification3([
            {label: 'Capacity', value: product.batteryCapacity},
            {label: 'Battery Type', value: product.batteryType},
            {label: 'Number Of Battery', value: product.numberOfBatteries},
            {label: 'Water & Dust Safety', value: product.waterAndDustSafety}
        ]);
        setSpecification4([
            {label: 'Home / Portable charger', value: product.homePortableChargingTime},
            {label: 'Fast Charger', value: product.fastChargerChargingTime}
        ]);
        setSpecification5([
            {label: 'Motor Type', value: product.motorType},
            {label: 'Power / Capacity', value: product.motorPeakPowerCapacity},
            {label: 'Peak Torque', value: product.peakTorque}
        ]);
        setSpecification6([
            {label: 'Screen Size', value: product.screenSize},
            {label: 'Screen Resolution', value: product.screenResolution},
            {label: 'Navigation system', value: product.navigationSystem},
            {label: 'Ram', value: product.ram},
            {label: 'Storage', value: product.storage},
            {label: 'Reverse', value: "NA"},
            {label: 'Cruise control', value: product.cruiseControl},
            {label: 'OTA updates', value: product.otaUpdates},
            {label: 'Geo Fencing', value: product.geoFencing},
            {label: 'Ride status', value: product.rideStatus},
            {label: 'Remote Boot Unlock', value: product.remoteBootUnlock},
            {label: 'Music', value: product.music},
            {label: 'Predictive Maintenance', value: product.predictiveMaintenance}
        ]);
        setSpecification7([
            {label: 'Bluetooth', value: product.bluetooth},
            {label: 'Internet', value: product.internet}
        ]);
        setSpecification8([
            {label: 'Front Brake Type', value: product.frontBrakeType},
            {label: 'Rear Brake Type', value: product.rearBrakeType},
            {label: 'ABS / CBS', value: product.brakeSystem},
            {label: 'Wheel Type', value: product.wheelType},
            {label: 'Front Wheel Size', value: "NA"},
            {label: 'Rear Wheel Size', value: "NA"},
            {label: 'Front Tyre Size', value: product.frontTyreSize},
            {label: 'Rear Tyre Size', value: product.rearTyreSize}
        ]);
        setSpecification9([
            {label: 'Front suspension', value: product.frontSuspension},
            {label: 'Rear Suspension', value: product.rearSuspension},
            {label: 'Frame Type', value: product.frameType},
            {label: 'Frame Material', value: product.frameMaterial},
            {label: 'Loading Capacity', value: product.loadingCapacity}
        ]);
        setSpecification10([
            {label: 'Kerb Weight', value: product.kerbWeight},
            {label: 'Overall Length', value: product.overallLength},
            {label: 'Overall Wading', value: product.overallWading},
            {label: 'Overall Height', value: product.overallHeight},
            {label: 'Wheel Base', value: product.wheelBase},
            {label: 'Seat Height', value: product.seatHeight},
            {label: 'Seat Length', value: product.seatLength},
            {label: 'Ground Clearance', value: product.groundClearance}
        ]);
        setSpecification11([
            {label: 'Headlight', value: product.headlight},
            {label: 'Taillights', value: product.tailLight},
            {label: 'Indicator', value: product.indicator},
            {label: 'Side Stand Motor Cut-off', value: product.sideStandMotorCutOff}
        ]);
        setSpecification12([
            {label: 'Battery Warranty', value: product.batteryWarranty},
            {label: 'Vehicle Warranty', value: product.vehicleWarranty},
            {label: 'Covered In Warranty', value: product.coveredInWarranty},
            {label: 'Not Covered In Warranty', value: product.notCoveredInWarranty}
        ]);
    }, [product]);

    return (
        <div>
            <div id="specification">
                <Specification title={sectionTitle1} fields={specification1}/>
                <Specification title={sectionTitle2} fields={specification2}/>
                <Specification title={sectionTitle3} fields={specification3}/>
                <Specification title={sectionTitle4} fields={specification4}/>
                <Specification title={sectionTitle5} fields={specification5}/>
                <Specification title={sectionTitle6} fields={specification6}/>
                <Specification title={sectionTitle7} fields={specification7}/>
                <Specification title={sectionTitle8} fields={specification8}/>
                <Specification title={sectionTitle9} fields={specification9}/>
                <Specification title={sectionTitle10} fields={specification10}/>
                <Specification title={sectionTitle11} fields={specification11}/>
                <Specification title={sectionTitle12} fields={specification12}/>
            </div>
        </div>
    );
}

export default ProductSpecifications;