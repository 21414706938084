import React, {useState} from 'react';

const Specification = ({title, fields}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="accordion">
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button
                        className={`accordion-button ${isCollapsed ? 'collapsed' : ''}`}
                        type="button"
                        onClick={toggleCollapse}
                        style={{fontWeight: 400, paddingBlockEnd: 6}}
                    >
                        {title}
                    </button>
                </h2>
                <div className={`accordion-collapse ${isCollapsed ? 'collapse' : 'show'}`}>
                    <div className="accordion-body">
                        {fields.map((field, index) => (
                            field.value !== null && field.value !== '' ? (
                                <div className="accordion-section" key={index}>
                                    <div className="accordion-field">
                                        <span className="">{field.label}</span>
                                    </div>
                                    <div className="accordion-value">
                                        <span className="spf-input">{field.value}</span>
                                    </div>
                                </div>
                            ) : null
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Specification;
