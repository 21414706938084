import React, {useState} from 'react';
import '../../Css/BackOffice.css';
import TestDrivesSection from "../Section/TestDrivesSection";
import BulkUploadDocumentSection from "../Section/BulkUploadDocumentSection";

function BackOffice(props) {
    const [activeIndex, setActiveIndex] = useState(1);


    function handleRequest(index) {
        setActiveIndex(index);
    }

    return (
        <div className="back-office-container">
            <div className="user-info-header">Back Office</div>
            <div className="back-office-action-container">
                <button className={`${activeIndex === 1 ? 'active-btn' : 'back-office-action'}`}
                     onClick={() => handleRequest(1)}
                >Test Drive Request
                </button>
                <button className={`${activeIndex === 2 ? 'active-btn' : 'back-office-action'}`}
                     onClick={() => handleRequest(2)}
                >Bulk Upload
                </button>
            </div>
            {activeIndex === 1 && (<div className="back-office-details-container">
                <TestDrivesSection/>
            </div>)}
            {activeIndex === 2 && (<div className="back-office-details-container">
                <BulkUploadDocumentSection/>
            </div>)}
        </div>
    );
}

export default BackOffice;