import React from 'react';

function BlogPageSection3({content}) {
    return (
        <div>
            {content != null && Array.isArray(content.paragraphs) && content.paragraphs.map((para, index) => (
                <div className="article-page-2 black">
                    <div className="article-content-2">
                        <div className="article-subheading">{content.headers[index]}</div>
                        <div className="article-para black">{para}</div>
                    </div>
                    {content.images !== null && content.images[index] !== 'null' ?
                        <img src={content.images[index]} alt="ev" className="article-image-2"/> : null}
                </div>
            ))}
        </div>
    );
}

export default BlogPageSection3;