import React, {useEffect, useState} from 'react';
import '../../Css/ProductDetailsInfo.css';
import {RWebShare} from "react-web-share";
import apiService from "../ApiService";
import {useStore} from "../StoreContext";
import {
    fetchProductDetails,
    showProductInfoForm,
    showProductInfoHighlightsForm
} from "../../Actions/ProductActions";
import {connect, useSelector} from "react-redux";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {useNavigate} from 'react-router-dom';
import ProductInfoForm from "../Form/ProductInfoForm";
import ProductInfoHighLightsForm from "../Form/ProductInfoHighLightsForm";


const ProductInfo = () => {
    const store = useStore();
    // console.log("product info page rendering", productDetails);
    const productDetails = useSelector(state => state.productReducer.product);
    const [allImages, setAllImages] = useState([]);
    const [activeColorIndex, setActiveColorIndex] = useState(0);
    const [activeImage, setActiveImage] = useState(allImages[0]);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [colors, setColors] = useState([[]]);
    const [isMobileView, setIsMobileView] = useState(false);
    const user = useSelector(state => state.userReducers.user);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 500);
        };
        handleResize(); // Set initial view on load
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const changeImage = (increment) => {
        const newIndex = (activeImageIndex + increment + allImages.length) % allImages.length;
        setActiveImageIndex(newIndex);
    };

    useEffect(() => {
        if (productDetails !== null && productDetails !== undefined)
            setAllImages([...productDetails.images.bike_images, ...productDetails.images.parts_images]);

        apiService.getColors(productDetails.brandName, productDetails.modelName).then(fetchedData => {
            console.log("product details colors", fetchedData);
            let colorCodes = [];
            // eslint-disable-next-line array-callback-return
            fetchedData.payload.colors.map(color => {
                const colors = color.split(",");
                console.log("colors", colors);
                let colorsList = [];
                // eslint-disable-next-line array-callback-return
                colors.map(c => {
                    if (c.trim() !== "") {
                        colorsList.push(c.trim());
                    }
                })

                colorCodes.push(colorsList);
            })
            console.log("color codes", colorCodes);
            setColors(colorCodes);
        }).catch(error => {
            console.log("error", error);
        })
    }, [productDetails]);

    useEffect(() => {
        const newActiveImage = allImages[activeImageIndex];
        setActiveImage(newActiveImage);
    }, [activeImageIndex, allImages]);

    const [likedProducts, setLikedProducts] = useState([]);

    const onLike = (productId) => {
        console.log("product id", productId);
        console.log("liked products: ", likedProducts);
        const updatedLikedProducts = likedProducts.includes(productId)
            ? likedProducts.filter(id => id !== productId)
            : [...likedProducts, productId];
        setLikedProducts(updatedLikedProducts);
    };

    function getProductDetails(color, index) {
        console.log("request for color", color)
        setActiveColorIndex(index);
        store.dispatch(fetchProductDetails({
            "modelName": productDetails.modelName,
            "colorCode": color
        }));
    }

    function openComparisonPage() {
        navigate('/compare', {state: {fromProductPage: true, productDetails}});
    }

    const showProductInfoUpdateForm = () => {
        store.dispatch(showProductInfoForm());
    }

    const showProductHighLightUpdateForm = () => {
        store.dispatch(showProductInfoHighlightsForm());
    }

    return (

        <div className="product-info-container">
            {/*<ProductInfoHeaderForm/>*/}
            <div className="product-info-header">
                {/*<div>*/}
                {/*    <img src="/images/icons/draw.png" alt="edit-icon" className="edit-icon"/>*/}
                {/*</div>*/}
                {isMobileView ? (<OwlCarousel className="owl-theme" items={1} loop nav>
                    {allImages.map((image, index) => (
                        <div key={index} className="itemm">
                            <img src={image} alt={`product-image-${index}`} className="product-img"/>
                        </div>
                    ))}
                </OwlCarousel>) : (<div className="product-img-div">
                    <button onClick={() => changeImage(-1)}><img src="/images/navigation/left-arrow.png"
                                                                 alt="left-arrow"/></button>
                    <div>
                        <img src={activeImage} alt="product-image1" className="product-img"/>
                    </div>
                    <button onClick={() => changeImage(1)}><img src="/images/navigation/right-arrow.png"
                                                                alt="right-arrow"/></button>
                </div>)}
                <div className="product-details-choose-color-con">
                    {Array.isArray(colors) &&
                        colors.map((color, index) => (
                            <div
                                className={`color-options ${activeColorIndex === index ? 'active-color' : ''}`}
                                key={index}
                                onClick={() => getProductDetails(color.length === 2 ? color[0] + "," + color[1] : color[0], index)}
                                style={{
                                    background: Array.isArray(color) && color.length === 2
                                        ? `linear-gradient(to right, ${color[0]}, ${color[1]})`
                                        : color[0]
                                }}
                            ></div>
                        ))}
                </div>
                {productDetails && <div className="product-details-container">
                    <div className="product-detail-share-options">
                        <img
                            src={likedProducts.includes(productDetails.brandId) ? "/images/red-heart.png" : "/images/heart.png"}
                            alt="like"
                            className="product-list-logo2"
                            onClick={() => onLike(productDetails.brandId)}
                        />
                        <RWebShare
                            data={{
                                text: productDetails.description,
                                url: window.location.href,
                                title: productDetails.modelName,
                            }}
                            onClick={() =>
                                console.log("shared successfully!")
                            }
                        >
                            <img src="/images/share.png" alt="share" className="product-list-logo2"/>
                        </RWebShare>
                        {user !== null ? <img src="/images/icons/draw.png"
                                              alt="edit-icon"
                                              className="product-list-logo2 edit-icon"
                                              onClick={showProductInfoUpdateForm}
                                              style={{width: 25}}
                        /> : null}
                        <ProductInfoForm/>
                    </div>
                    <div className="dets">
                        <div style={{fontWeight: 600, fontSize: 24}}>{productDetails.brandName}</div>
                        <div style={{fontWeight: 500, fontSize: 16}}>Model: {productDetails.title}</div>
                        <div style={{
                            color: "#37337a",
                            fontSize: 15,
                            paddingTop: 10,
                            fontWeight: "bold"
                        }}><span
                            style={{marginRight: 10}}>Ex-ShowRoom Price: &#8377;{productDetails.exShowroomPrice}</span>
                            {/*<span style={{fontSize: 14, color: "green", fontWeight: "bold"}}>Get 5% Off</span>*/}
                        </div>
                    </div>

                    {/*<TestDriveButton divClassName="product-details-page-btn" buttonClassName="offer-btn"/>*/}

                    <button className="offer-btn" onClick={openComparisonPage}>Compare Products</button>
                    <button className="offer-btn">Check On-Road Price</button>
                </div>}
            </div>
            <div className="head">
                <pre className="tex">  ———  ———  ———  ———  ———  ———  ———  ———  ———  ——— HIGHLIGHTS ———  ———  ———  ———  ———  ———  ———  ———  ———  ———  </pre>
            </div>
            <ProductInfoHighLightsForm/>
            {productDetails && <div className="product-info-key-spec-container">

                {user !== null ? <img src="/images/icons/draw.png"
                                      alt="edit-icon"
                                      className="product-list-logo2 edit-icon icon-position"
                                      onClick={showProductHighLightUpdateForm}
                /> : null}

                <div className="product-info-spec-list">
                    <img src="/images/icons/lighting.png" alt="battery" className="spec-key-icon"/>
                    <div className="spec-key-name">Charging Time</div>
                    <div className="t1">{productDetails.homePortableChargingTime}</div>
                </div>
                <div className="product-info-spec-list">
                    <img src="/images/icons/location.png" alt="mileage" className="spec-key-icon"/>
                    <div className="spec-key-name">Range</div>
                    <div className="t1">{productDetails.idcRange}</div>
                </div>
                <div className="product-info-spec-list">
                    <img src="/images/icons/tools-and-utensils.png" alt="deshboard" className="spec-key-icon"/>
                    <div className="spec-key-name">Speed</div>
                    <div className="t1">{productDetails.topSpeed}</div>
                </div>
                <div className="product-info-spec-list">
                    <img
                        src="/images/icons/engine.png"
                        alt="charging-time"
                        className="spec-key-icon"
                        style={{width: 60, height: 60, marginBottom: -8}}
                    />
                    <div className="spec-key-name">Motor Power</div>
                    <div className="t1">{productDetails.motorPeakPowerCapacity}</div>
                </div>
                <div className="product-info-spec-list">
                    <img src="/images/icons/weight.png" alt="kerbWeight" className="spec-key-icon"/>
                    <div className="spec-key-name">Weight</div>
                    <div className="t1">{productDetails.kerbWeight}</div>
                </div>
                <div className="product-info-spec-list">
                    <img src="/images/icons/car-battery.png" alt="fastCharging" className="spec-key-icon"/>
                    <div className="spec-key-name">Battery Capacity</div>
                    <div className="t1">{productDetails.batteryCapacity}</div>
                </div>
            </div>}

        </div>
    );
}


const mapStateToProps = state => ({
    product: state.productReducer.product
});

export default connect(mapStateToProps)(ProductInfo);