import ApiService from "../Components/ApiService";

export const FETCH_BRANDS_SUCCESS = "FETCH_BRANDS_SUCCESS";
export const FETCH_BRANDS_FAILURE = "FETCH_BRANDS_FAILURE";



const fetchBrandsSuccess = (brands) => ({
    type: FETCH_BRANDS_SUCCESS,
    payload: brands,
});

const fetchBrandsFailure = (error) => ({
    type: FETCH_BRANDS_FAILURE,
    payload: error,
});

export const fetchBrandSummary = () => (dispatch) => {
    ApiService.fetchBrands()
        .then((data) => {
            console.log("Brands data is", data);
            dispatch(fetchBrandsSuccess(data.payload));
        })
        .catch((error) => {
            console.log("Error occurred while fetching brands data", error);
            dispatch(fetchBrandsFailure(error));
        });
};
