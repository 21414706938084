import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {TokenProvider} from "./Components/TokenContext";
import {StoreProvider, useStore} from "./Components/StoreContext";
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {persistor} from "./Components/Utils/Store";

const Root = () => {
    const contextStore = useStore();

    return (
        <Provider store={contextStore}>
            <PersistGate loading={null} persistor={persistor}>
                <TokenProvider>
                    <App/>
                </TokenProvider>
            </PersistGate>
        </Provider>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <StoreProvider>
            <TokenProvider>
                    <Root/>
            </TokenProvider>
        </StoreProvider>
    </React.StrictMode>
);
