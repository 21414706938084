import React from 'react';
import {useLocation} from "react-router-dom";
import Footer from "./Footer";

function ConditionalFooter() {
    const location = useLocation();

    // Define an array of route paths to exclude from the wildcard route
    const excludedRoutes = ['/', '/products', '/profile', '/forgotpassword',
        '/compare', '/blogs', '/blog', '/about',
        '/contact', '/details/**', '/blog/**'];
    const {pathname} = location;
    // Determine whether to show the Navbar and Footer based on the current location
    const showFooter = excludedRoutes.includes(pathname) || (
        pathname.startsWith('/details/') ||
        pathname.startsWith('/blog/') ||
        pathname.startsWith('/products/'));
    return (
        <div>
            {showFooter && <Footer/>}
        </div>
    );
}

export default ConditionalFooter;