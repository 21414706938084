import React, {useEffect, useState} from 'react';
import {useStore} from "../StoreContext";
import {fetchTestDriveRequests, updateTestDriveRequest} from "../../Actions/TestDriveActions";
import {useToken} from "../TokenContext";
import {connect, useSelector} from "react-redux";
import {formatDate} from "../Utils/ConfigUtil";

function TestDrivesSection(props) {
    const store = useStore();
    const [showForm, setShowForm] = useState(false);
    const testDriveRequests = useSelector(state => state.testDriveReducers.testDriveRequests);
    const [formData, setFormData] = useState({
        "allocatedTime": null,
        "testDriveStatus": null,
        "testDriveRequestId": null
    });
    const {token} = useToken();

    useEffect(() => {
        store.dispatch(fetchTestDriveRequests(token));
    }, []);

    function showTestDriveForm(id) {
        setShowForm(true);
        setFormData({
            ...formData,
            testDriveRequestId: id
        });
    }

    const handleBackgroundClick = () => {
        setShowForm(false);
    };

    const handleSubmitButton = (e) => {
        e.preventDefault();
        console.log("form data", formData);
        store.dispatch(updateTestDriveRequest(token, formData));
        store.dispatch(fetchTestDriveRequests(token));
        setShowForm(false);
    }

    const handleChange = (e) => {
        let {name, value} = e.target;
        if (name === "allocatedTime") {
            value = formatDate(value);
        }
        setFormData({...formData, [name]: value});
    }

    return (
        <div className="test-drive-section">
            <div>
                {showForm && (
                    <div className="form-container" onClick={handleBackgroundClick}>
                        <form onSubmit={handleSubmitButton} className="test-drive-form1"
                              onClick={(e) => e.stopPropagation()}>
                            <div>
                                <label className="test-drive-form-lable">Allocated Time</label>
                                <input
                                    type="datetime-local"
                                    name="allocatedTime"
                                    placeholder="Time"
                                    className="test-drive-update-form-input"
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <label className="test-drive-form-lable">Status</label>
                                <select
                                    name="testDriveStatus"
                                    className="test-drive-update-form-input"
                                    value={formData.testDriveStatus}
                                    onChange={handleChange}
                                >
                                    <option>IN_PROGRESS</option>
                                    <option>DOCUMENT_VERIFICATION_IN_PROGRESS</option>
                                    <option>DOCUMENT_VERIFIED</option>
                                    <option>TEST_DRIVE_COMPLETED</option>
                                </select>
                            </div>
                            <button
                                type="submit"
                                className="test-drive-form-submit-btn">
                                Update
                            </button>
                        </form>
                    </div>
                )}
            </div>
            <ul>
                {testDriveRequests !== undefined && testDriveRequests.length !== 0 && testDriveRequests.map(testDriveRequest => (
                    <li className="test-drive-list">
                        <img
                            src="/images/icons/draw.png"
                            alt='edit'
                            style={{
                                width: 25,
                                position: "absolute",
                                right: 10,
                                cursor: "pointer",
                                backgroundColor: "lightgray",
                                borderRadius: 30
                            }}
                            onClick={() => showTestDriveForm(testDriveRequest.id)}
                        />
                        <div className="test-drive-item">
                            <span className="test-drive-item-key">Customer Name</span>
                            <span className="test-drive-item-val">{testDriveRequest.customerName}</span>
                        </div>
                        <div className="test-drive-item">
                            <span className="test-drive-item-key">Mobile</span>
                            <span className="test-drive-item-val">{testDriveRequest.mobile}</span>
                        </div>
                        <div className="test-drive-item">
                            <span className="test-drive-item-key">State</span>
                            <span className="test-drive-item-val">{testDriveRequest.state}</span>
                        </div>
                        <div className="test-drive-item">
                            <span className="test-drive-item-key">City</span>
                            <span className="test-drive-item-val">{testDriveRequest.city}</span>
                        </div>
                        <div className="test-drive-item">
                            <span className="test-drive-item-key">Pin Code</span>
                            <span className="test-drive-item-val">{testDriveRequest.pincode}</span>
                        </div>
                        <div className="test-drive-item">
                            <span className="test-drive-item-key">Address</span>
                            <span className="test-drive-item-val">{testDriveRequest.address}</span>
                        </div>
                        <div className="test-drive-item">
                            <span className="test-drive-item-key">Status</span>
                            <span className="test-drive-item-val">{testDriveRequest.status}</span>
                        </div>
                        <div className="test-drive-item">
                            <span className="test-drive-item-key">Allocated Time</span>
                            <span className="test-drive-item-val">{testDriveRequest.allocatedTime}</span>
                        </div>
                        <div className="test-drive-item">
                            <span className="test-drive-item-key">Created On</span>
                            <span className="test-drive-item-val">{testDriveRequest.createdOn}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

const mapStateToProps = state => ({
    testDriveRequests: state.testDriveReducers.testDriveRequests
});


export default connect(mapStateToProps)(TestDrivesSection);