import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import '../../Css/Home.css'
import '../../Css/SlideShow.css'
import ExploreElectricVehicle from './ExploreElectricVehicle';
import TopMostTrendingBikes from './TopMostTrendingBikes';
import {Link} from "react-router-dom";
import ServicesCard from './ServicesCard';
import Chatbot from "../../assets/services_images/Chatbot.png"
import fuel_saving from "../../assets/services_images/Saving_calulators.png"
import Emi from "../../assets/services_images/EMI_Calulator.png"
import Finance from "../../assets/services_images/BankLoan.png"
import Insurance from "../../assets/services_images/Insurance.png"
import Subsidy from "../../assets/services_images/Subsidy.png"
import Road_side from "../../assets/services_images/Road_side_assistance.png"
import evconversion from "../../assets/services_images/evconversion.png"
import charging from "../../assets/services_images/charging_Location.png"
import RTO from "../../assets/services_images/RTO.png"
import testRide from "../../assets/services_images/testRide.png"
import after from "../../assets/services_images/after_sale_service.png"
import Layout from "../Layout";
import FAQ from "../Section/FAQ";
import {useSelector} from "react-redux";
import store from "../Utils/Store";
import {fetchHomePageData} from "../../Actions/HomePageAction";

const serviceLive = [{
    name: "GPT Powered ChatBot",
    img: Chatbot,
    info: "Have you Doubt about electric vehicles? Don't worry—our GPT Chat bot is here to help! Engage with our " +
        "intelligent assistant anytime and get instant answers to all your EV-related queries. Whether it's about " +
        "vehicle features, charging options, or incentives, just ask away and learn everything you need to know.."
}, {
    name: "Fuel Saving Calculator",
    img: fuel_saving,
    info: "See how much you could save annually by switching to an electric vehicle with our Fuel Savings Calculator." +
        " Compare the expenses of operating a petrol vehicle against an EV based on your yearly mileage." +
        " Embrace eco-friendly driving and turn your fuel cost savings into real financial benefits."
}, {
    name: "EMI Calculator",
    img: Emi,
    info: "Plan your electric vehicle purchase with ease using our EMI Calculator." +
        " Input different loan amounts, interest rates, and terms to find the most affordable financing option from " +
        "various banks. Drive smarter by aligning your savings with manageable monthly payments."
}, {
    name: "Finance",
    img: Finance,
    info: "Explore flexible Finance options tailored to your needs, facilitating affordable ownership and hassle free transactions"
}]
const serviceSoon = [
    {
        name: "Insurance",
        img: Insurance,
        info: "Secure your electric vehicle and its battery with the right insurance plan. Opt for coverage that " +
            "provides paperless claim processes and swift resolution times, ensuring you get back on the road quickly " +
            "and with ease."
    }, {
        name: "Subsidy & Tax benifit",
        img: Subsidy,
        //doubt
        info: "Does your state offer subsidies for electric vehicles? Find out how you can reduce the purchase cost " +
            "with available state subsidies and make the eco-friendly switch more affordable."
    }, {
        name: "RTO Facilities",
        img: RTO,
        info: "Access RTO Facilities for seamless registration licensing, and compliance services, ensuring legal compliance and peace of mind."
    }, {
        name: "Test Ride",
        img: testRide,
        info: "Discover the perfect ride before you buy! Schedule a test drive of your dream vehicle on our website and" +
            " explore its features and performance firsthand. Let your driving experience guide your purchase decision," +
            " ensuring the vehicle fits all your needs seamlessly. Dive into adventure—book your test ride today!"
    }, {
        name: "Road Side Assistance(RSA)",
        img: Road_side,
        info: "Never worry on the road again! Our Roadside Assistance service provides you with peace of mind, " +
            "offering 24/7 support for any roadside emergencies. From flat tires to engine troubles, " +
            "we're just a call away"
    }, {
        name: "Spare parts & Accessories",
        img: after,
        info: "Don't let minor issues keep you off the road! Order genuine spare parts directly from our online store" +
            " and choose to install them yourself or with the help of a local technician. Ensure your vehicle runs " +
            "smoothly with quality parts you can trust."
    }, {
        name: "Conversion Kits",
        img: evconversion,
        info: "Ready to go electric? Convert your petrol vehicle into an electric one with our government-approved " +
            "conversion kits. Find the perfect match for your existing bike or scooty and connect with certified " +
            "service centers for a seamless transformation."
    }, {
        name: "Charging Location",
        img: charging,
        info: "Plan your journeys confidently with access to our extensive charging network. Locate charging " +
            "stations along your route effortlessly and ensure your electric vehicle is always powered for the road ahead."
    }]
const Home = () => {
    const homePageData = useSelector(state => state.homePageReducers.data);
    console.log("home page data", homePageData);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [images, setImages] = useState(null);
    const title = "Top Most Trending Bikes";


    useEffect(() => {
        window.scrollTo(0, 0);
        store.dispatch(fetchHomePageData());
    }, [])

    useEffect(() => {
        const width = window.innerWidth;
        if (homePageData !== null && homePageData !== undefined) {
            const images = width < 700
                ? homePageData.images.TOP_BANNER_MOBILE_VIEW : homePageData.images.TOP_BANNER;
            setImages(images);
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 3000); // Change slide every 3 seconds
            return () => clearInterval(interval);
        }
    }, [homePageData]);


    const bikeRequest =
        {
            "direction": "ASC",
            "limit": 100,
            "offset": 0
        }

    return (
        <Layout
            title="Electra Wheeler"
            description="Welcome to electra wheeler, find your perfact ev on electra wheeler"
            children={<section id="home">
                {images && <div className="slideshow">
                    <div className="top-banner">
                        <img src={images[currentIndex]} alt="slide"
                             className="slide-image"/>
                    </div>
                    <div className="dots">
                        {images.map((_, index) => (
                            <span
                                key={index}
                                className={`dot ${index === currentIndex ? 'active' : ''}`}
                                onClick={() => setCurrentIndex(index)}
                            />
                        ))}
                    </div>
                </div>}
                {/*{homePageData && <img src={homePageData.images.TOP_BANNER[0]} alt="top banner" className="top-banner"/>}*/}
                <div className="container-fluid px-0">
                    <div className="container1">
                        <div className="electra-wheeler">
                            <p className="Electra">ElectraWheeler</p>
                            <p className="buying mb-3">Now buying an electric vehicle is simple</p>
                            <p className="subtitle mt-5">
                                Your <span className="span1">Green-Ride</span> is just a click away
                            </p>
                            <Link to="/products">
                                <button className="btn btn-outline-light" id="start">
                                    start your journey now{' '}
                                    <span>
                                    {' '}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            fill="currentColor"
                                            className="bi bi-arrow-right-circle"
                                            viewBox="0 0 16 16"
                                        >
                                        <path
                                            fillRule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                                        />
                                    </svg>
                                </span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                {/*<NewUpcomingBikes/>*/}
                <TopMostTrendingBikes title={title} request={bikeRequest}/>
                <ExploreElectricVehicle request={bikeRequest}/>
                {/*<EmiCalculator/>*/}
                <div className='ourServices'>
                    <h2 className='heading-ourServices'>Our Services</h2>
                    <div className='servicesLiveWrapper'>
                        <h2 className='servicesLiveHeading'>Coming Soon...</h2>
                        <div className='servicesLive'>
                            {serviceLive.map((Element) => {
                                return <ServicesCard serviceImg={Element.img} serviceName={Element.name}
                                                     serviceInfo={Element.info}/>
                            })}
                        </div>
                    </div>
                    <div className='servicesSoonWrapper'>
                        <h2 className='servicesSoonHeading'>Upcoming</h2>
                        <div className='servicesSoon'>
                            {serviceSoon.map((Element) => {
                                return <ServicesCard serviceImg={Element.img} serviceName={Element.name}
                                                     serviceInfo={Element.info}/>
                            })}
                        </div>
                    </div>

                </div>
                <FAQ referenceId={1} pageName={'HOMEPAGE'}/>
                {/*{<Section5/>}  commented for now*/}
                {/*<Section7/>*/}
                {/*<Section6/>*/}
                {/*<Section8/>*/}
                {/*<Section9/>*/}
                {/*<Disclaimer/>*/}

            </section>}
        >

        </Layout>
    );
}

export default Home;
