import "../../Css/ProductArticle.css";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import BlogPageSection1 from "../Section/BlogPageSection1";
import BlogPageSection2 from "../Section/BlogPageSection2";
import BlogPageSection3 from "../Section/BlogPageSection3";
import BlogPageConclusion from "../Section/BlogPageConclusion";
import {fetchArticleDetails} from "../../Actions/ArticleActions";
import store from "../Utils/Store";
import {connect, useSelector} from "react-redux";
import Layout from "../Layout";

const ProductArticle = () => {
    const {headline} = useParams();
    const article = useSelector(state => state.articleReducers.article);
    console.log("article details", article);

    useEffect(() => {
        window.scrollTo(0, 0);
        store.dispatch(fetchArticleDetails({"headline": headline}));
    }, [headline]);


    return (
        headline ? <Layout
            title={headline}
            description={headline}
            children={<div>
                {article !== null && article !== undefined && Array.isArray(article.content) && (
                    <div className="aricle-page">
                        <BlogPageSection1 content={article.content[0]}
                                          productName={article.productModelName}/>
                        {article.content.map((content, index) => (
                            index >= 1 && index < article.content.length - 1 ? index % 2 === 0 ?
                                <BlogPageSection3 key={index} content={content}/> :
                                <BlogPageSection2 key={index} content={content}/> : null
                        ))}
                        {article.content.length > 0 &&
                            <BlogPageConclusion content={article.content[article.content.length - 1]}/>
                        }
                    </div>
                )}
            </div>}
        >
        </Layout> : <div>Loading...</div>
    )
};

const mapStateToProps = state => ({
    article: state.articleReducers.article
})
export default connect(mapStateToProps)(ProductArticle);
