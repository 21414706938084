import React from 'react';

function RoadLine({headline}) {
    return (
        <div className="head line">
            <pre className="tex">———  ———  ———  ———  ———  ———  ———  ———  ———  ——— {headline} ———  ———  ———  ———  ———  ———  ———  ———  ———  ———</pre>
        </div>
    );
}

export default RoadLine;