import React, {useEffect} from 'react';
import '../../Css/AboutUs.css';
import Layout from "../Layout";
import {fetchHomePageData} from "../../Actions/HomePageAction";
import store from "../Utils/Store";
import {useSelector} from "react-redux"; // Import the CSS file

function AboutUs() {
    const aboutData = useSelector(state => state.homePageReducers.data);

    useEffect(() => {
        document.body.classList.add('page-background-color');
        return () => {
            document.body.classList.remove('page-background-color');
        };
    }, []);

    return (
        <Layout
            title="About Us"
            description="Who we are on electra wheeler"
            children={aboutData && <div className="about-us-container">
                <div className="about-us-section">
                    <h1 className="section-title">About Us</h1>
                    <p className="about-us-para">{aboutData.aboutUs}</p>
                </div>

                <div className="about-us-section">
                    <h2 className="section-title">Our Vision</h2>
                    <p className="about-us-para">{aboutData.vision}</p>
                </div>

                <div className="about-us-section">
                    <h2 className="section-title">Our Mission</h2>
                    <p className="about-us-para">{aboutData.mission}</p>
                </div>

                <div className="about-us-section">
                    <h2 className="section-title">Our Position</h2>
                    <p className="about-us-para">{aboutData.position}</p>
                </div>

                <div className="about-us-section">
                    <h2 className="section-title">Why Choose Us</h2>
                    <ul className="about-us-para">
                        {aboutData.whyChooseUs.map((choice, index) => (
                            <li key={index}>{choice}</li>
                        ))}
                    </ul>
                </div>

                <div className="about-us-section">
                    <h2 className="section-title">Our Team</h2>
                    <ul className="team-detail-container">
                        {Object.entries(aboutData.teamDetails).map(([profileUrl, teamDetails], index) => (
                            <li key={index} className="team-details-list">
                                <img src={profileUrl}
                                     style={{width: 160, height: 200, borderRadius: 20}}
                                     alt={`Profile of ${teamDetails[1]}`}/>
                                <div className="about-us-member-container">
                                    <div style={{fontWeight: 500}}>{teamDetails[0]}</div>
                                    <p style={{fontWeight: 400, fontSize: 13}}>{teamDetails[1]}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>}
        >

        </Layout>
    );
}

export default AboutUs;
