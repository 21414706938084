import "../../Css/Products.css";

import React, {useEffect, useState} from "react";
import {useSearchParams,} from "react-router-dom";
import ProductItem from "./ProductItem";
import {fetchProducts} from "../../Actions/ProductActions";
import {useSelector} from "react-redux";
import store from "../Utils/Store";
import {fetchBrandSummary} from "../../Actions/BrandSummaryAction";
import EvPricingDetails from "../Form/EvPricingDetails";
import Layout from "../Layout";

const Products = () => {
    const data = useSelector(state => state.productReducer.products.data);
    const brands = useSelector(state => state.brandSummaryReducers.brands);
    let [searchParams, setSearchParams] = useSearchParams();
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [request, setRequest] = useState(null);
    const productsPerPage = 12;
    const category = searchParams.get('category');
    const brand = searchParams.get('brand');
    let displayedProducts = [];

    if (Array.isArray(data?.data)) {
        const startIdx = (currentPage - 1) * 12;
        const endIdx = currentPage * 12;
        displayedProducts = data.data.slice(startIdx, endIdx);
    }


    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setRequest({
            offset: 0,
            limit: 100
        });

        let searchRequest = {};
        if (category !== null) {
            searchRequest['productCategory'] = category;
            setRequest(searchRequest);
        }
        if (brand !== null) {
            searchRequest['brandName'] = brand;
            setRequest(searchRequest);
        }

        store.dispatch(fetchBrandSummary());
    }, [category, brand, currentPage]);

    useEffect(() => {
        store.dispatch(fetchProducts(request));
    }, [request]);


    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < 10) {
            setCurrentPage(currentPage + 1);
        }
    };

    useEffect(() => {
        if (currentPage !== 1 && request === null) {
            const offset = (currentPage - 1) * productsPerPage;
            setRequest({
                limit: productsPerPage,
                offset: offset,
            });
        }
    }, [currentPage]);


    const renderPageButtons = () => {
        const pageButtons = [];
        console.log("current page is ", currentPage);
        console.log("data is ", data);
        let init = Math.floor((currentPage + 4) / 5);
        let num_pages = Math.floor((data !== undefined && data !== null ? data.recordCount : 1 + 11) / 12);
        console.log("number of pages are ", num_pages);
        for (let i = init; i <= Math.min(num_pages, 5 + init - 1); i++) {
            pageButtons.push(
                <button id={i}
                        key={i}
                        onClick={() => handlePageClick(i)}
                        className={currentPage === i ? "active-page" : ""}
                >
                    {i}
                </button>
            );
        }
        return pageButtons;
    };

    const searchProduct = (e) => {
        const {value} = e.target;
        console.log("search api calling for value", value)
        setSearchParams((params) => {
            params.set('brand', value);
            return params;
        });
    }

    function fetchBikes(e) {
        const {value} = e.target;
        setSearchParams((params) => {
            params.set('category', value);
            return params;
        });
    }

    function fetchBrandNameData(e) {
        const {value} = e.target;
        setSearchParams((params) => {
            params.set('brand', value);
            return params;
        });
    }

    return (
        <Layout
            title="Expore Your Electric Vehicle On ElectrWheeler"
            description="Expore Your Electric Vehicle On ElectrWheeler"
            children={<div className="product-main-container">
                <div className="product-list-container1">
                    <div id="product-list-header-section">
                        <input
                            className="search-product-bar emi-calculator-input"
                            placeholder="Search"
                            onChange={searchProduct}
                        />
                    </div>
                    <div className="product-category-container">

                        <select className="category-btn-container"
                                value={category}
                                onChange={fetchBikes}
                        >
                            <option>Category</option>
                            <option
                                value="Electric Bike"
                            >
                                Bikes
                            </option>
                            <option
                                value="Electric Scooter"
                            >
                                Scooters
                            </option>
                        </select>
                        <div>

                            {category === 'Electric Bike' ?
                                <select
                                    className="category-btn-container"
                                    value={brand}
                                    onChange={fetchBrandNameData}
                                >
                                    <option>Brands</option>
                                    {Array.isArray(brands.electricBikes)
                                        ? brands.electricBikes.map((data) => (
                                            <option
                                                value={data.brandName}>
                                                {data.brandName}
                                            </option>
                                        ))
                                        : null}
                                </select> :
                                <select
                                    className="category-btn-container"
                                    onChange={fetchBrandNameData}
                                    value={brand}
                                >
                                    <option>Brands</option>
                                    {Array.isArray(brands.electricScooty)
                                        ? brands.electricScooty.map((data) => (
                                            <option value={data.brandName}>
                                                {data.brandName}
                                            </option>
                                        ))
                                        : null}
                                </select>}
                        </div>
                    </div>


                    <div>
                        <div className="product-list-container">
                            {error ? (
                                <div className="error-message">Error: {error.message}</div>
                            ) : Array.isArray(data?.data) ? (
                                displayedProducts.map((product) => (
                                    <ProductItem
                                        key={product.brandId}
                                        product={product}
                                    />
                                ))
                            ) : (
                                <div className="loading-message">
                                    Loading ...
                                </div>

                            )}
                        </div>
                        <div className="product-list-cart-button-pagination">
                            <button onClick={handlePrevClick} disabled={currentPage === 1}>
                                Prev
                            </button>
                            {renderPageButtons()}
                            <button onClick={handleNextClick}
                                    disabled={data !== null && currentPage === Math.floor((data.recordCount + 11) / 12)}>
                                Next
                            </button>
                        </div>
                        <EvPricingDetails/>
                    </div>
                </div>

            </div>}
        >
        </Layout>
    );
};

export default Products;
