import React from 'react';

function BlogPageSection1({content, productName}) {
    return (
        <div className="article-page-1">
            <div className="article-name">{productName}</div>
            {content != null && Array.isArray(content.paragraphs) && content.paragraphs.map((para, index) => (
                <div className="article-content-con">
                    <div className="article-content-1">
                        <div className="article-title">
                            {content.headers[index]}
                        </div>
                        <div className="article-para first">
                            {para}
                        </div>
                    </div>
                    {content.images !== null && content.images[index] !== 'null' ?
                        <img className="article-image-1" alt="ev" src={content.images[index]}/> : null}
                </div>


            ))}
        </div>
    );
}

export default BlogPageSection1;