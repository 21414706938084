import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "../Css/Navbar.css";
import {useToken} from "./TokenContext";
import Service from "./Service";
import ServiceMobile from "./ServiceMobile";
import {ToastContainer} from "react-toastify";
import Hamburger from "hamburger-react";
import TestDriveForm from "./Form/TestDriveForm";
import {updateUser} from "../Actions/UserActions";
import {useSelector} from "react-redux";
import {useStore} from "./StoreContext";
import SignIn from "./Form/SigIn";
import {showLoginForm} from "../Actions/ProductActions";

const Navbar = () => {
    const [showProfileBtn, setProfileBtn] = useState(false);
    const {user, token} = useSelector(state => state.userReducers)
    const store = useStore();

    useEffect(() => {
        if (token === null) {
            setProfileBtn(false);
            store.dispatch(updateUser(null));
        }
    }, [token]);

    const showProfileMenu = () => {
        setProfileBtn(!showProfileBtn);
    };
    const [openMenu, setOpenMenu] = useState(false);

    function toggleArrow(id) {
        const arrow = document.getElementById(id);
        arrow.classList.toggle('rotate');
    }

    const handleLoginForm = () => {
        store.dispatch(showLoginForm());
    }

    return (
        <nav className="navbar1">
            <ul className={`navbar-list`}>
                <li className="navbar-item">
                    <Link to="/" className="navbar-link">
                        <div style={{fontSize: 24}}>
                            <span style={{color: "#17ce17", marginRight: 5}}>Electra</span>
                            <span>Wheeler</span>
                            <div style={{fontSize: 11, lineHeight: 0, color: "gray", marginLeft: 5}}>Explore Your
                                Electric Vehicle
                            </div>
                        </div>
                    </Link>
                </li>
                <li className="navbar-item">
                    <Link to="/products" className="navbar-link">
                        Bikes & Scooters
                    </Link>
                </li>
                <li className="navbar-item">
                    <Link to="/compare" className="navbar-link">
                        Compare
                    </Link>
                </li>
                <li className="navbar-item">
                    <Service/>
                </li>
                <li className="navbar-item">
                    <Link to="/about" className="navbar-link">
                        About
                    </Link>
                </li>
                <li className="navbar-item">
                    <Link to="/blogs" className="navbar-link">
                        Blogs
                    </Link>
                </li>
                <li className="navbar-item tab active">
                    <div id="user-profile-btn">
                        {token !== null && user !== null && user.name !== undefined ? (
                            <Link to="profile" className="navbar-link">
                                <div
                                    style={{display: "flex", alignItems: "center", cursor: "pointer"}}
                                    // onClick={() => showProfileMenu()}
                                ><img
                                    src="/images/icons/user1.png"
                                    alt="user profile img"
                                    style={{
                                        width: 25,
                                        color: "white",
                                        height: 25,
                                        borderRadius: 50,
                                        cursor: "pointer",
                                    }}
                                />
                                    <span style={{
                                        marginLeft: 5,
                                        textTransform: "capitalize"
                                    }}>{user.name}</span>
                                </div>
                            </Link>
                        ) : (
                            <div className="navbar-link"
                                 style={{
                                     background: "black",
                                     color: "white",
                                     paddingTop: 4,
                                     paddingBottom: 4,
                                     paddingLeft: 15,
                                     paddingRight: 15,
                                     borderRadius: 1,
                                     cursor: "pointer"
                                 }}
                                 onClick={handleLoginForm}
                            >
                                Login/Signup
                            </div>
                        )}
                    </div>
                </li>
            </ul>
            <div className="nav-mobile">
                <img
                    className="EW-logo"
                    src="/images/logo/logo.png"
                    alt="logo"
                />
            </div>
            <div className="hamburger">
                <Hamburger size={20} toggle={setOpenMenu} toggled={openMenu}/>
            </div>
            {openMenu ? (
                <div className="full-screen-overlay">
                    {/*<img*/}
                    {/*    alt="logo"*/}
                    {/*    className="EW-logo-1"*/}
                    {/*    src="/images/logo/ElectraWheeler_logo.png"*/}
                    {/*/>*/}
                    <div className="EW-logo-1"></div>
                    <ul className="navbar-list-mobile">
                        <li className="navbar-item" onClick={() => setOpenMenu(false)}>
                            <Link to="/" className="navbar-link">
                                <img src="/images/icons/home.png" alt="home" className="nav-icon-img"/>
                                Home
                            </Link>
                        </li>
                        <li className="navbar-item" onClick={() => setOpenMenu(false)}>
                            <Link to="/products" className="navbar-link">
                                <img src="/images/icons/motorcycle.png" alt="home" className="nav-icon-img"/>
                                Bikes & Scooters
                            </Link>
                        </li>
                        <li className="navbar-item drop">
                            <ServiceMobile setOpenMenu={setOpenMenu} openMenu={openMenu} toggleArrow={toggleArrow}/>
                        </li>
                        <li>
                        </li>
                        <li className="navbar-item" onClick={() => setOpenMenu(false)}>
                            <Link to="/about" className="navbar-link">
                                <img src="/images/icons/about.png" alt="about" className="nav-icon-img"/>
                                About
                            </Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/articles" className="navbar-link">
                                <img src="/images/icons/article.png" alt="article" className="nav-icon-img"/>
                                Blogs
                            </Link>
                        </li>
                        <li className="navbar-item tab active">
                            <div
                                id="user-profile-btn"
                                onClick={() => toggleArrow("arrow2")}
                            >
                                {user !== null && user.name !== undefined ? (
                                    <Link to="/profile" className="navbar-link">
                                        <div
                                            onClick={() => showProfileMenu()}
                                        >

                                            <img
                                                src="/images/icons/user.png"
                                                alt="user profile img"
                                                className="user-profile-img nav-icon-img"
                                            />
                                            {user.name}
                                            <img
                                                src="/images/icons/down-arrow.png"
                                                alt="down-arrow"
                                                className="down-arrow"
                                                id="arrow2"
                                            />
                                        </div>
                                    </Link>
                                ) : (
                                    <div className="navbar-link" onClick={() => {
                                        setOpenMenu(false);
                                        handleLoginForm();
                                    }}>
                                        <img src="/images/icons/next.png" alt="login" className="nav-icon-img"/>
                                        Login/Signup
                                    </div>
                                )}
                            </div>
                        </li>
                    </ul>


                </div>
            ) : null}
            <TestDriveForm/>
            <SignIn/>
            <ToastContainer/>
        </nav>
    );
};

export default Navbar;
