import React, {useState, useEffect} from "react";
import "../../Css/ServicesCard.css";

const ServicesCard = ({serviceImg, serviceName, serviceInfo}) => {
    const [click, setClick] = useState(false);
    const [cardHeight, setCardHeight] = useState("340px"); // Initial height
    const [wordsToShow, setWordsToShow] = useState(70); // Initial number of words to show

    useEffect(() => {
        const handleResize = () => {
            // Adjust the number of words based on device width
            const width = window.innerWidth;
            console.log("width is ", width)
            if (width < 400) {
                setCardHeight("300px");
            } else if (width < 600) {
                console.log("It is desktop")
                setWordsToShow(50);
                setCardHeight("290px");
            } else if (width >= 600 && width < 1024) {
                setWordsToShow(75);
            } else {
                setWordsToShow(100);
            }
        };

        handleResize(); // Initial adjustment
        window.addEventListener("resize", handleResize); // Listen for resize events

        return () => {
            window.removeEventListener("resize", handleResize); // Clean up event listener
        };
    }, []);

    const toggleClick = () => {
        setClick(!click);
        const width = window.innerWidth;
        if (width < 400) {
            setCardHeight(click ? "300px" : "450px");
        } else if (width < 500) {
            setCardHeight(click ? "290px" : "540px");
        } else {
            setCardHeight(click ? "240px" : "470px");
        }
    };

    return (
        <div className="services_card" style={{height: cardHeight}}>
            <div className="service_name">
                <img className="serviceImage" src={serviceImg} alt={serviceName}/>
                <div className="cardContent">
                    <p className="serviceTitle">{serviceName}</p>
                    {click ? (
                        <p className="serviceAbout">{serviceInfo}</p>
                    ) : (
                        <p className="serviceAbout">{serviceInfo.slice(0, wordsToShow)}</p>
                    )}
                    <a className="view-service" onClick={toggleClick}>
                        {click ? "View less" : "View more"}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ServicesCard;
