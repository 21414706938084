import React, {useState} from 'react';
import '../Css/Service.css';
import {useStore} from "./StoreContext"; // Import the CSS file
import {
    showTestDriveForm
} from "../Actions/ProductActions";
import {showEmiCalculatorForm} from "../Actions/PropertyActions";
import EmiCalculator from "./Home/EmiCalculator";

function Service() {
    const store = useStore();
    const [display, setDisplay] = useState(false);
    const [display1, setDisplay1] = useState(false);

    const showServices = () => {
        setDisplay1(true);
        setDisplay(true);
    }
    const hideService = () => {
        setDisplay(false);
    }
    const hideService1 = () => {
        setDisplay1(false);
    }

    function showForm() {
        // document.getElementById("test-drive-form-container").style.display = "block";
        store.dispatch(showTestDriveForm());
    }

    function showEmiCalculator() {
        store.dispatch(showEmiCalculatorForm(true))
    }

    return (
        <div className="service-container">
            <div onClick={showServices} onMouseLeave={hideService1} style={{zIndex: 10}}>Service</div>
            {/*<div className="service-form-div">*/}
            {/*</div>*/}
            <ul id="service-options-container" style={display || display1 ? {display: "block"} : {display: "none"}}
                onMouseLeave={hideService}>
                {/*<li onClick={showForm} className={"disabled"}>Free Test Drive</li>*/}
                <li onClick={showEmiCalculator}>Emi Calculator</li>
            </ul>
            <EmiCalculator/>
        </div>
    );
}

export default Service;
