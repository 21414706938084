import ApiService from "../Components/ApiService";
import apiService from "../Components/ApiService";
import {hideLoginForm} from "./ProductActions";
import {TokenProvider, useToken} from "../Components/TokenContext";

export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const UPDATE_LOGIN_USER_TOKEN = "UPDATE_LOGIN_USER_TOKEN";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const UPDATE_USER = "UPDATE_USER";
export const ADD_INTO_WISHLIST = "ADD_INTO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";
export const FETCH_WISHLIST = "FETCH_WISHLIST";
export const ADD_WISHLIST = "ADD_WISHLIST";

const fetchUserSuccess = (user) => ({
    type: 'FETCH_USER_SUCCESS',
    payload: user,
});
const sendUserOtpSuccess = (mobile) => ({
    type: 'SEND_OTP_SUCCESS',
    payload: mobile,
});

export const updateLoginUserToken = (token) => ({
    type: 'UPDATE_LOGIN_USER_TOKEN',
    payload: token,
});

const fetchUserFailure = (error) => ({
    type: 'FETCH_USER_FAILURE',
    payload: error,
});

export const updateUser = (user) => ({
    type: UPDATE_USER,
    payload: user
})

export const addIntoWishlist = (brandId) => ({
    type: ADD_INTO_WISHLIST,
    payload: brandId
})

export const removeFromWishlist = (brandId) => ({
    type: REMOVE_FROM_WISHLIST,
    payload: brandId
})

export const addWishlist = (wishlist) => ({
    type: ADD_WISHLIST,
    payload: wishlist
})


export const fetchUser = (token) => (dispatch) => {
    ApiService.fetchUserData(token)
        .then((data) => {
            console.log("user api data is", data);
            dispatch(fetchUserSuccess(data.payload));
            dispatch(addWishlist(data.payload.wishlist));
        })
        .catch((error) => {
            console.log("Error occurred while calling user details api", error);
            dispatch(fetchUserFailure(error));
        });
};

export const addAndRemoveIntoWishlist = (action, brandId) => (dispatch) => {
    apiService.addAndRemoveFormWishList(action, brandId)
        .then((data) => {
            console.log("product added into wishlist");
            // eslint-disable-next-line default-case
            switch (action) {
                case "ADD":
                    dispatch(addIntoWishlist(brandId));
                    break;
                case "REMOVE":
                    dispatch(removeFromWishlist(brandId));
                    break;
            }
        }).catch((error) => {
        console.log("error occurred while " + action + "ing product into wishlist", error);
    })
}

export const sendOtpOnMobile = (mobile) => (dispatch) => {
    apiService.sendOtp(mobile)
        .then((data) => {
            console.log("otp response", data);
            dispatch(sendUserOtpSuccess(mobile))
        }).catch((error) => {
        console.log("error occurred while sending otp on mobile", mobile);
    })
}

export const verifyOtp = (mobile, otp) => (dispatch) => {
    apiService.verifyOtp(mobile, otp)
        .then((data) => {
            dispatch(updateLoginUserToken(data.payload.body.access_token));
            dispatch(fetchUser(data.payload.body.access_token));
            dispatch(hideLoginForm());
        }).catch((error) => {
        console.log("error occurred while sending submitting otp on mobile", mobile);
    })
}