import ApiService from "../Components/ApiService";

export const FETCH_HOME_PAGE_DATA_SUCCESS = "FETCH_HOME_PAGE_DATA_SUCCESS";

export const fetchHomePageDataSuccess = (data) => ({
    type: FETCH_HOME_PAGE_DATA_SUCCESS,
    payload: data
})
export const fetchHomePageData = () => (dispatch) => {
    ApiService.fetchHomePageData().then(response => {
        console.log("home page response", response.payload);
        dispatch(fetchHomePageDataSuccess(response.payload));
    }).catch(e => {
        throw new Error(e);
    })
}