import {
    FETCH_ARTICLE_DETAIL_SUCCESS,
    FETCH_ARTICLE_FAILED,
    FETCH_ARTICLE_SUCCESS
} from "../Actions/ArticleActions";

const initialState = {
    articles: [],
    article: null,
    error: null,
}

const articleReducers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ARTICLE_SUCCESS:
            return {
                ...state,
                articles: action.payload
            }
        case FETCH_ARTICLE_FAILED:
            return {
                ...state,
                error: action.payload
            }
        case FETCH_ARTICLE_DETAIL_SUCCESS:
            return {
                ...state,
                article: action.payload
            }
        default:
            return state;
    }
}


export default articleReducers;