// NotFoundPage.jsx

import React from 'react';
import '../Css/NotFoundPage.css'; // Import the CSS file for styling

const NotFoundPage = () => {
    return (
        <div className="not-found-container">
            <h1 id="h1">404 - Page Not Found</h1>
            <p id="p">The page you are looking for does not exist.</p>
        </div>
    );
}

export default NotFoundPage;
