import {
    HIDE_LOGIN_FORM, HIDE_PRICING_DETAILS,
    HIDE_PRODUCT_INFO_FORM, HIDE_PRODUCT_INFO_HIGHLIGHTS_FORM, SHOW_LOGIN_FORM, SHOW_PRICING_DETAILS,
    SHOW_PRODUCT_INFO_FORM,
    SHOW_PRODUCT_INFO_HIGHLIGHTS_FORM
} from "../Actions/ProductActions";
import {SHOW_EMI_CALCULATOR_FORM} from "../Actions/PropertyActions";

const initialState = {
    serviceFormDisplay: 'none',
    testDriveFormDisplay: 'none',
    productHeaderFormDisplay: 'none',
    testDriveUpdateForm: false,
    productInfoForm: false,
    productInfoHighLightsForm: false,
    showLoginForm: false,
    showPricingDetails: false,
    showEmiCalculator: false
}
const PropertiesReducers = (state = initialState, action) => {
    console.log("property reducer initial state", state);
    switch (action.type) {
        case 'SHOW_SERVICE_OPTIONS':
            return {
                ...state,
                serviceFormDisplay: 'block'
            }
        case 'HIDE_SERVICE_OPTIONS':
            return {
                ...state,
                serviceFormDisplay: 'none'
            }
        case 'SHOW_TEST_DRIVE_FORM':
            return {
                ...state,
                testDriveFormDisplay: 'block'
            }
        case 'HIDE_TEST_DRIVE_FORM':
            return {
                ...state,
                testDriveFormDisplay: 'none'
            }
        case 'SHOW_PRODUCT_HEADER_FORM':
            return {
                ...state,
                testDriveUpdateForm: true
            }
        case 'HIDE_PRODUCT_HEADER_FORM':
            return {
                ...state,
                testDriveUpdateForm: false
            }
        case SHOW_PRODUCT_INFO_FORM:
            return {
                ...state,
                productInfoForm: true
            }
        case HIDE_PRODUCT_INFO_FORM:
            return {
                ...state,
                productInfoForm: false
            }
        case SHOW_PRODUCT_INFO_HIGHLIGHTS_FORM:
            return {
                ...state,
                productInfoHighLightsForm: true
            }
        case HIDE_PRODUCT_INFO_HIGHLIGHTS_FORM:
            return {
                ...state,
                productInfoHighLightsForm: false
            }
        case SHOW_LOGIN_FORM:
            return {
                ...state,
                showLoginForm: true
            }
        case HIDE_LOGIN_FORM:
            return {
                ...state,
                showLoginForm: false
            }
        case SHOW_PRICING_DETAILS:
            return {
                ...state,
                showPricingDetails: true
            }
        case HIDE_PRICING_DETAILS:
            return {
                ...state,
                showPricingDetails: false
            }
        case SHOW_EMI_CALCULATOR_FORM:
            return {
                ...state,
                showEmiCalculator: action.payload
            }

        default:
            return state;
    }
}

export default PropertiesReducers;