import React, {useEffect, useState} from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "bootstrap/dist/css/bootstrap.css";

import "../../Css/ExploreElectricVehical.css";
import apiService from "../ApiService";
import {useNavigate} from "react-router-dom";

const ExploreElectricVehicle = (props) => {
    const [activeTab, setActiveTab] = useState("brands");
    const [products, setProducts] = useState(null);
    const [brands, setBrands] = useState(null);
    const [loading, setLoading] = useState(true);
    const [request, setRequest] = useState({
        ...props.request,
        productCategory: "Electric Bike",
    });
    const [error, setError] = useState(null);
    const navigate = useNavigate("");

    const options = {
        items: 4, // Display 4 items at a time
        loop: true,
        nav: true, // Enable navigation buttons
        navText: ['<', '>'], // Custom navigation text
        margin: 10,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 4
            }
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);

        setRequest((prevRequest) => {
            let updatedRequest = {...prevRequest};

            switch (tab) {
                case "scooters":
                    updatedRequest.productCategory = "Electric Scooter";
                    break;
                case "bikes":
                    updatedRequest.productCategory = "Electric Bike";
                    break;
                default:
                    break;
            }

            console.log("sending request for ", updatedRequest);
            return updatedRequest;
        });
    };

    useEffect(() => {
        // console.log("Before API call");
        setLoading(true);

        apiService
            .fetchProductData(request)
            .then((fetchedData) => {
                console.log("API call successful", fetchedData.payload);
                setProducts(fetchedData.payload);
                setLoading(false);
            })
            .catch((fetchError) => {
                console.error("API call error:", fetchError);
                setError(fetchError);
                setLoading(false);
            });
    }, [request]);

    useEffect(() => {
        apiService
            .fetchBrands()
            .then((fetchedData) => {
                console.log("brand summary api call successful", fetchedData.payload);
                setBrands(fetchedData.payload);
            })
            .catch((error) => {
                console.error("Error occurred while calling brand-summary api:", error);
                setError(error);
            });
    }, []);

    const openProductDetails = (product) => {
        navigate(`/details/${product.titleUrl}`);
    };

    const fetchBrandNameData = (brandName) => {
        navigate(`/products/?brand=${brandName}`);
    };

    return (
        <div id="ExploreElectric" className="explore-your-bike-container">
            <div className="container mt-3 text-center">
                <div className="align-items-center">
                    <h1 className="section2">Explore your Electric Vehicle</h1>
                    <div
                        className="btn-toolbar mt-3"
                        role="toolbar"
                        aria-label="Toolbar with button groups"
                    >
                        <div
                            className="btn-group mx-auto"
                            role="group"
                            aria-label="First group"
                        >
                            <div
                                style={{
                                    background: "rgba(78.63, 78.63, 78.63, 0.60)",
                                    borderRadius: "80px",
                                    padding: 5
                                }}
                                className="d-flex align-items-center"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-outline-dark mx-2 ee-btn ${
                                        activeTab === "brands" && "active"
                                    }`}
                                    style={{borderRadius: "39px", border: "none"}}
                                    onClick={() => handleTabChange("brands")}
                                >
                                    Brands
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-outline-dark mx-2 ee-btn ${
                                        activeTab === "scooters" && "active"
                                    }`}
                                    style={{borderRadius: "39px", border: "none"}}
                                    onClick={() => handleTabChange("scooters")}
                                >
                                    Scooters
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-outline-dark mx-2 ee-btn ${
                                        activeTab === "bikes" && "active"
                                    }`}
                                    style={{borderRadius: "39px", border: "none"}}
                                    onClick={() => handleTabChange("bikes")}
                                >
                                    Bikes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {activeTab === "scooters" && ( // Render scooter content
                    <div className="row mt-3 brand-list" id="scootersList">
                        <div className="container-fluid">
                            {loading ? (
                                <div className="loading">Loading...</div>
                            ) : (
                                <OwlCarousel className="owl-theme" {...options}>
                                    {Array.isArray(products.data)
                                        ? products.data.map((product, index) => (
                                            <div
                                                className="item"
                                                onClick={() => openProductDetails(product)}
                                                key={index}
                                            ><img
                                                className="img trending-bike-img"
                                                src={product.images.bike_images[0]}
                                                alt="Item 1"
                                            />
                                                <div className="trending-logo">
                                                    {product.title}
                                                </div>
                                                <div className="trending-logo">Ex-Showroom
                                                    Price &#8377;{product.exShowroomPrice}</div>
                                            </div>
                                        ))
                                        : null}
                                </OwlCarousel>
                            )}
                        </div>
                    </div>
                )}
                {activeTab === "bikes" && ( // Render bike content
                    <div className="row mt-3 brand-list" id="bikesList"
                         style={{paddingBottom: 30}}
                    >
                        <div className="container-fluid">
                            <div className="row title" style={{marginBottom: "20px"}}>
                                {/* Content for the "bikesList" title can go here */}
                            </div>
                        </div>
                        <div className="container-fluid">
                            {loading ? (
                                <div className="loading">Loading...</div>
                            ) : (
                                <OwlCarousel className="owl-theme" {...options}>
                                    {Array.isArray(products.data)
                                        ? products.data.map((product) => (
                                            <div
                                                className="item"
                                                onClick={() => openProductDetails(product)}
                                            >
                                                <img
                                                    className="img trending-bike-img"
                                                    src={product.images.bike_images[0]}
                                                    alt="Item 1"
                                                />
                                                <div className="trending-logo">
                                                    {product.title}
                                                </div>
                                                <div className="trending-logo">Ex-Showroom
                                                    Price &#8377;{product.exShowroomPrice}</div>
                                            </div>
                                        ))
                                        : null}
                                </OwlCarousel>
                            )}
                        </div>
                    </div>
                )}
                {activeTab === "brands" && ( // Render brand content
                    <div className="brand-list-container">
                        {/* Brand logos code can go here */}
                        <div className="brand-list-container-row">
                            {brands && Array.isArray(brands.electricBikes)
                                ? brands.electricBikes.map((data) => (
                                    <div className="brand-list-container-col"
                                         onClick={() => fetchBrandNameData(data.brandName)}>
                                        <div>
                                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                            <img
                                                src={data.logo}
                                                alt="Image 3"
                                                className="brand-logo-img"

                                            />
                                        </div>
                                    </div>
                                ))
                                : null}

                            {brands && Array.isArray(brands.electricScooty)
                                ? brands.electricScooty.map((data) => (
                                    <div className="brand-list-container-col"
                                         onClick={() => fetchBrandNameData(data.brandName)}>
                                        <div>
                                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                            <img
                                                src={data.logo}
                                                alt="brand logo"
                                                className="brand-logo-img"

                                            />
                                        </div>
                                    </div>
                                ))
                                : null}

                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExploreElectricVehicle;
