import {FETCH_FAQ_SUCCESS} from "../Actions/FaqActions";
import {FETCH_HOME_PAGE_DATA_SUCCESS} from "../Actions/HomePageAction";

const initialState = {
    faqs: null,
    data: null
}

const HomePageReducers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FAQ_SUCCESS:
            return {
                ...state,
                faqs: action.payload
            }
        case FETCH_HOME_PAGE_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state
    }
}

export default HomePageReducers;