// productReducer.js
import {
    ADD_REVIEW, FETCH_CITIES, FETCH_ON_ROAD_PRICE, FETCH_PRODUCTS_FAILED, FETCH_PRODUCTS_SUCCESS,
    FETCH_REVIEWS,
    SELECT_PRODUCT_DETAILS
} from '../Actions/ProductActions';

const initialProducts = {
    data: [],
    error: null
}

const initialState = {
    product: {},
    // eslint-disable-next-line no-use-before-define
    products: initialProducts,
    reviews: [],
    loading: false,
    error: null,
    pricingDetail: null,
    cities: null
};

const ProductReducer = (state = initialState, action) => {
    console.log("product reducer", action.type);
    switch (action.type) {
        case SELECT_PRODUCT_DETAILS:
            return {
                ...state,
                product: action.payload.product
            };
        case FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: {
                    data: action.payload,
                    error: null
                }
            }
        case FETCH_PRODUCTS_FAILED:
            return {
                ...state,
                products: {
                    data: null,
                    error: action.payload
                }
            }
        case FETCH_REVIEWS:
            return {
                ...state,
                reviews: action.payload
            }
        case ADD_REVIEW:
            console.log("payload", action.payload);
            return {
                ...state,
                reviews: [action.payload, ...state.reviews]
            }
        case FETCH_ON_ROAD_PRICE:
            return {
                ...state,
                pricingDetail: action.payload
            }
        case FETCH_CITIES:
            return {
                ...state,
                cities: action.payload
            }

        default:
            return state;
    }
};

export default ProductReducer;
