import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./Components/Home/Home"
import About from "./Components/About/AboutUs";
import ForgotPassword from "./Components/ForgotPassword";
import Contact from "./Components/Contact";
import Products from "./Components/ProductDetails/Products";
import ProductDetails from "./Components/ProductDetails/ProductDetails";
import ProductCompare from "./Components/ProductDetails/ProductCompare";
import ProductArticle from "./Components/ProductDetails/ProductArticle";
import UserProfile from "./Components/UserProfile/UserProfile";
import Articles from "./Components/ProductDetails/Articles";
import PageNotFound from "./Components/PageNotFound";
import ConditionalNavbar from "./Components/ConditionalNavbar";
import ConditionalFooter from "./Components/ConditionalFooter";

function App() {
    const request = {
        "direction": "ASC",
        "limit": 12,
        "offset": 0
    }
    return (
        <div>
            <Router>
                <ConditionalNavbar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/products/" element={<Products request={request}/>}/>
                    <Route path="/details/:titleUrl" element={<ProductDetails/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/profile" element={<UserProfile/>}/>
                    <Route path="/forgotpassword" element={<ForgotPassword/>}/>
                    <Route path="/compare" element={<ProductCompare/>}/>
                    <Route path="/blog/:headline" element={<ProductArticle/>}/>
                    <Route path="/blogs" element={<Articles/>}/>
                    <Route path="*" element={<PageNotFound/>}/>
                </Routes>
                <ConditionalFooter/>
            </Router>
        </div>
    );
}

export default App;
