import {
    ADD_INTO_WISHLIST, ADD_WISHLIST,
    FETCH_USER_FAILURE,
    FETCH_USER_SUCCESS, REMOVE_FROM_WISHLIST, SEND_OTP_SUCCESS, UPDATE_LOGIN_USER_TOKEN, UPDATE_USER
} from "../Actions/UserActions";

const initialState = {
    user: null,
    userMobile: null,
    token: null,
    wishlist: [],
    error: null
}

const UserReducers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                error: null,
            }
        case FETCH_USER_FAILURE:
            return {
                ...state,
                user: {},
                error: action.payload
            }
        case SEND_OTP_SUCCESS:
            return {
                ...state,
                userMobile: action.payload
            }
        case UPDATE_USER:
            return {
                ...state,
                user: action.payload,
                error: null
            }
        case ADD_INTO_WISHLIST:
            return {
                ...state,
                wishlist: [action.payload, ...state.wishlist]
            }
        case REMOVE_FROM_WISHLIST:
            if (state.wishlist.length === 0) {
                return state;
            }
            return {
                ...state,
                wishlist: state.wishlist.filter(item => item !== action.payload)
            }
        case ADD_WISHLIST:
            return {
                ...state,
                wishlist: action.payload
            }
        case UPDATE_LOGIN_USER_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        default:
            return state;
    }
}

export default UserReducers;