import ApiService from "../Components/ApiService";

export const SELECT_PRODUCT_DETAILS = "SELECT_PRODUCT_DETAILS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILED = "FETCH_PRODUCTS_FAILED";
export const FETCH_REVIEWS = "FETCH_REVIEWS";
export const ADD_REVIEW = "ADD_REVIEW";
export const SHOW_TEST_DRIVE_FORM = 'SHOW_TEST_DRIVE_FORM';
export const HIDE_TEST_DRIVE_FORM = 'HIDE_TEST_DRIVE_FORM';
export const HIDE_PRODUCT_INFO_FORM = 'HIDE_PRODUCT_INFO_FORM';
export const SHOW_PRODUCT_INFO_FORM = 'SHOW_PRODUCT_INFO_FORM';
export const SHOW_PRODUCT_INFO_HIGHLIGHTS_FORM = 'SHOW_PRODUCT_INFO_HIGHLIGHTS_FORM';
export const HIDE_PRODUCT_INFO_HIGHLIGHTS_FORM = 'HIDE_PRODUCT_INFO_HIGHLIGHTS_FORM';
export const SHOW_LOGIN_FORM = 'SHOW_LOGIN_FORM';
export const HIDE_LOGIN_FORM = 'HIDE_LOGIN_FORM';
export const SHOW_PRICING_DETAILS = 'SHOW_PRICING_DETAILS';
export const HIDE_PRICING_DETAILS = 'HIDE_PRICING_DETAILS';
export const FETCH_ON_ROAD_PRICE = 'FETCH_ON_ROAD_PRICE';
export const FETCH_CITIES = 'FETCH_CITIES';

export const fetchProductDetailsSuccess = (product) => ({
    type: SELECT_PRODUCT_DETAILS,
    payload: {product},
});
export const fetchReviews = (data) => ({
    type: FETCH_REVIEWS,
    payload: data.payload
})

export const addReview = (data) => ({
    type: ADD_REVIEW,
    payload: data.payload
})

export const showTestDriveForm = () => ({
    type: SHOW_TEST_DRIVE_FORM,
    payload: null
})

export const hideTestDriveForm = () => ({
    type: HIDE_TEST_DRIVE_FORM,
    payload: null
})

export const showProductInfoForm = () => ({
    type: SHOW_PRODUCT_INFO_FORM,
    payload: null
})

export const hideProductInfoForm = () => ({
    type: HIDE_PRODUCT_INFO_FORM,
    payload: null
})

export const showProductInfoHighlightsForm = () => ({
    type: SHOW_PRODUCT_INFO_HIGHLIGHTS_FORM,
    payload: null
})

export const hideProductInfoHighlightsForm = () => ({
    type: HIDE_PRODUCT_INFO_HIGHLIGHTS_FORM,
    payload: null
})

export const hideLoginForm = () => ({
    type: HIDE_LOGIN_FORM,
    payload: null
})

export const showLoginForm = () => ({
    type: SHOW_LOGIN_FORM,
    payload: null
})

export const changePricingDetailsDisplay = (type, showPricingDetails) => ({
    type: type,
    payload: showPricingDetails
})

export const fetchProductsSuccess = (products) => ({
    type: FETCH_PRODUCTS_SUCCESS,
    payload: products
})

export const fetchProductFailed = (error) => ({
    type: FETCH_PRODUCTS_FAILED,
    payload: error
})

export const fetchOnRoadPriceSuccess = (data) => ({
    type: FETCH_ON_ROAD_PRICE,
    payload: data
})

export const fetchCitiesSuccess = (data) => ({
    type: FETCH_CITIES,
    payload: data
})

export const fetchOnRoadPrice = (city, modelName) => (dispatch) => {
    ApiService.fetchOnRoadPrice(city, modelName)
        .then((data) => {
            console.log("fetch on road price api data", data);
            dispatch(fetchOnRoadPriceSuccess(data.payload));
            dispatch(fetchCities(data.payload.modelId));
        })
        .catch((error) => {
            console.log("Error occurred while fetching product data", error);
        });
}

export const fetchCities = (modelId) => (dispatch) => {
    console.log("fetching cities");
    ApiService.fetchCity(modelId)
        .then((data) => {
            dispatch(fetchCitiesSuccess(data.payload));
        })
        .catch((error) => {
            console.log("Error occurred while fetching cities", error);
        });
}

export const fetchProducts = (request) => (dispatch) => {
    console.log("fetching product data for", request);
    ApiService.fetchProductData(request)
        .then((data) => {
            dispatch(fetchProductsSuccess(data.payload));
        })
        .catch((error) => {
            console.log("Error occurred while fetching product data", error);
            dispatch(fetchProductFailed(error));
        });
};

export const fetchProductDetails = (request) => (dispatch) => {
    ApiService.fetchProductData(request)
        .then((data) => {
            console.log("fetched product details data", data);
            dispatch(fetchProductDetailsSuccess(data.payload.data[0]));
        })
        .catch((error) => {
            console.log("Error occurred while fetching product data", error);
            // dispatch(fetchProductFailed(error));
        });
};

