import {useSelector} from "react-redux";
import {useStore} from "../StoreContext";
import {hideProductInfoForm} from "../../Actions/ProductActions";


function ProductInfoForm() {
    const showForm = useSelector(state => state.propertiesReducers.productInfoForm);
    const store = useStore();
    const handleBackgroundClick = () => {
        store.dispatch(hideProductInfoForm());
    };

    const handleChange = () => {

    }

    const handleSubmitButton = () => {

    }

    return (
        <div>
            {showForm && (<div className="form-container" onClick={handleBackgroundClick}>
                <form onSubmit={handleSubmitButton} className="update-product-info-form"
                      onClick={(e) => e.stopPropagation()}>
                    <div>
                        <div className="input-tag">
                            <label className="test-drive-form-lable">Brand Name</label>
                            <input
                                type="text"
                                name="Brand Name"
                                placeholder="Brand Name"
                                className="test-drive-update-form-input"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="input-tag">
                            <label className="test-drive-form-lable">Model Name</label>
                            <input
                                type="text"
                                name="Model Name"
                                placeholder="Model Name"
                                className="test-drive-update-form-input"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="input-tag">
                            <label className="test-drive-form-lable">Ex-Showroom Price</label>
                            <input
                                type="number"
                                name="Ex-Showroom Price"
                                placeholder="Ex-Showroom Price"
                                className="test-drive-update-form-input"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="input-tag">
                            <label className="test-drive-form-lable">Discount %</label>
                            <input
                                type="number"
                                name="discount"
                                placeholder="Discount Percentage"
                                className="test-drive-update-form-input"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="test-drive-form-submit-btn">
                        Update
                    </button>
                </form>
            </div>)}
        </div>
    );
}

export default ProductInfoForm;