import {React, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import "../../Css/UserProfile.css";
import TestDrive1 from './TestDrive1';
import {IoBagHandleOutline} from "react-icons/io5";
import {MdOutlineAdminPanelSettings} from "react-icons/md";
import {RiMotorbikeLine} from "react-icons/ri";
import {CiHeart} from "react-icons/ci";
import {IoSettingsOutline} from "react-icons/io5";
import {addWishlist, fetchUser, updateLoginUserToken} from "../../Actions/UserActions";
import {useStore} from "../StoreContext";
import {useToken} from "../TokenContext";
import {useNavigate} from "react-router-dom";
import UserInfo from "./UserInfo";
import BackOffice from "./BackOffice";
import Layout from "../Layout";

const UserProfile = () => {
    const [currentTabText, setCurrentTabText] = useState();
    const [activeTab, setActiveTab] = useState(0);
    const {user, token} = useSelector(state => state.userReducers);
    console.log("user profile", user);
    const store = useStore();
    const {updateToken} = useToken();
    const navigate = useNavigate();
    const handleTabClick = (text, index) => {
        store.dispatch(fetchUser(token));
        setCurrentTabText(text);
        setActiveTab(index);
    };

    useEffect(() => {
        if (user === undefined || user === null) {
            console.log("routing to login page", user);
            navigate("/");
        }
    }, []);

    const handleLogOut = () => {
        console.log("logging out user");
        store.dispatch(addWishlist([]));
        store.dispatch(updateLoginUserToken(null));
        updateToken(null);
        navigate("/");
    };

    function handleBackOfficeBtn(text, index) {
        setCurrentTabText(text);
        setActiveTab(index);
    }

    return (
        <Layout
            title="User Profile"
            description="Electra Wheeler User Profile Page"
            children={<div className="profile">

                <div className="smallnav">
                    <span id="homet">Home</span>
                    <span className="arrow">></span>
                    <span id="myacc">My Account</span>
                    <span className="arrow">></span>
                    <span id="tdrive">{currentTabText}</span>
                </div>


                <div className="profiletable">
                    <div id="myac1">My Account</div>
                    <div className="tab-container">
                        <div className="tabs">
                            <div
                                className={`tab-button ${activeTab === 0 ? 'active' : ''}`}
                                onClick={() => handleTabClick("Profile Settings", 0)}
                                tabIndex={0}
                            >
                                <IoSettingsOutline className='ics'/>
                                Profile Settings
                            </div>

                            <div
                                className={`tab-button ${activeTab === 1 ? 'active' : ''}`}
                                onClick={() => handleTabClick("Test Drive", 1)}
                                tabIndex={0}
                            >
                                <RiMotorbikeLine className='ics'/>
                                Test Drive
                            </div>
                            <div
                                className={`tab-button ${activeTab === 2 ? 'active' : ''}`}
                                onClick={() => handleTabClick("My Orders", 2)}
                                tabIndex={0}
                            >
                                <IoBagHandleOutline className='ics'/>
                                My Orders
                            </div>
                            <div
                                className={`tab-button ${activeTab === 4 ? 'active' : ''}`}
                                onClick={() => handleTabClick("My Wishlist", 4)}
                                tabIndex={0}
                            >
                                <CiHeart className='ics'/>
                                My Wishlist
                            </div>
                            {user !== undefined && user !== null && user.authorities !== undefined && user.authorities.includes('VIEW_TEST_DRIVE_REQUEST') && (
                                <div
                                    className={`tab-button back-office ${activeTab === 5 ? 'active' : ''}`}
                                    onClick={() => handleBackOfficeBtn("Back Office", 5)}
                                    tabIndex={0}
                                >
                                    <MdOutlineAdminPanelSettings className="ics"/>
                                    Back Office
                                </div>)}
                            {/* Add more tabs as needed */}
                        </div>
                        <div className="user-profile-tab-content">
                            {activeTab === 0 && (
                                <div className="tab-panel">
                                    <UserInfo user={user}/>
                                </div>
                            )}
                            {activeTab === 1 && (
                                user.testDriveResponses[0] !== undefined ? (
                                    <div className="tab-panel">
                                        <TestDrive1/>
                                    </div>
                                ) : (
                                    <div style={{
                                        position: "relative",
                                        top: 60,
                                        padding: 20,
                                        backgroundColor: "white",
                                        fontSize: 25,
                                        fontWeight: 400,
                                        borderRadius: 3
                                    }}>Book
                                        Your First Test Drive</div>
                                ))}
                            {activeTab === 4 && (
                                <div className="tab-panel">
                                    <p>Tab 3 Content</p>
                                </div>
                            )}
                            {activeTab === 3 && (
                                <div className="tab-panel">
                                    <p>Tab 4 Content</p>
                                </div>
                            )}
                            {activeTab === 2 && (
                                <div className="tab-panel">
                                    <p>My Order</p>
                                </div>
                            )}
                            {activeTab === 5 && (
                                <div className="tab-panel">
                                    <BackOffice/>
                                </div>
                            )}

                        </div>
                    </div>
                    <button id="logbt" onClick={handleLogOut}>Logout</button>
                </div>


            </div>}
        >
        </Layout>
    )
}

export default UserProfile;


