import React from 'react';

function BlogPageConclusion({content}) {
    return (
        <div className="article-page-conclusion">
            {content !== null && Array.isArray(content.paragraphs) && content.paragraphs.map((para, index) => (
                <div>
                    <div className="article-subheading-conclusion">{content.headers[index]}</div>
                    <div className="article-para conclusion">{para}</div>
                </div>
            ))
            }
        </div>
    );
}

export default BlogPageConclusion;