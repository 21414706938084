import React, {useEffect, useState} from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "bootstrap/dist/css/bootstrap.css";
import "../../Css/ExploreElectricVehical.css";
import apiService from "../ApiService";
import {useNavigate} from "react-router-dom";

const TopMostTrendingBikes = (props) => {
    const [products, setProducts] = useState(null);
    const [loading, setLoading] = useState(true);
    const [request, setRequest] = useState(props.request);
    const [error, setError] = useState(null);
    const navigate = useNavigate("");
    const [title, setTitle] = useState(props.title);

    useEffect(() => {
        setLoading(true);

        apiService.fetchProductData(request)
            .then((fetchedData) => {
                console.log("API call successful", fetchedData.payload);
                setProducts(fetchedData.payload);
                setLoading(false);
            })
            .catch((fetchError) => {
                console.error("product fetch api call error:", fetchError);
                setError(fetchError);
                setLoading(false);
            });
    }, [request]);

    const openProductDetails = (product) => {
        navigate(`/details/${product.titleUrl}`);
    };

    const options = {
        items: 4,
        loop: true,
        nav: true,
        margin: 8,
        navText: ['<', '>'],
        responsive: {
            0: {
                items: 1 // Change the number of items displayed on mobile (at 0px width)
            },
            500: {
                items: 3
            },
            1000: {
                items: 4
            }
        },
    };

    return (
        <div id="ExploreElectric">
            <div className="container mt-3 text-center">
                <div className="align-items-center">
                    <div className="section2">{title}</div>
                </div>
                <div className="row mt-3 brand-list" id="bikesList">
                    <div className="container-fluid">
                        {loading ? (<div className="loading">Loading...</div>) : (
                            <OwlCarousel className="owl-theme" {...options}>
                                {products != null && Array.isArray(products.data) ? products.data.map((product, index) => (
                                    <div className="item"
                                         onClick={() => openProductDetails(product)}
                                         key={index}
                                    >
                                        <img
                                            className="img trending-bike-img"
                                            style={{height: props.imgHeight}}
                                            src={product.images.bike_images[0]}
                                            alt="Item 1"/>
                                        <div className="trending-logo">{product.title}</div>
                                        <div className="trending-logo">Ex-Showroom
                                            Price &#8377;{product.exShowroomPrice}</div>
                                    </div>)) : null}
                            </OwlCarousel>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopMostTrendingBikes;
