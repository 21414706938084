import React, {useState} from "react";
import "../Css/Service.css";
import {useStore} from "./StoreContext";
import {
    showTestDriveForm
} from "../Actions/ProductActions";
import {useSelector} from "react-redux";
import {showEmiCalculatorForm} from "../Actions/PropertyActions";

const ServiceMobile = ({openMenu, setOpenMenu, toggleArrow}) => {
    const store = useStore()
    const user = useSelector(state => state.userReducers.user);
    const [serviceList, setServiceList] = useState({"display": "none"});

    function handleServices() {
        if (serviceList.display === "block") {
            setServiceList({display: "none"});
        } else {
            setServiceList({
                display: "block",
            });
        }
    }

    function showTestDrive() {
        console.log("show test drive form");
        store.dispatch(showTestDriveForm())
    }

    function showEmiCalculator() {
        store.dispatch(showEmiCalculatorForm(true))
    }

    return (
        <div onClick={() => {
            handleServices();
            toggleArrow("arrow1");
        }} className="service-container navbar-link">
            <img src="/images/icons/front-desk.png" alt="service" className="nav-icon-img"/>
            <span>Service</span>
            <span><img
                src="/images/icons/down-arrow.png"
                alt="down-arrow"
                id="arrow1"
                className="down-arrow"
            /></span>

            <ul
                id="service-options-container"
                style={serviceList}
            >
                <li onClick={() => {
                    showEmiCalculator()
                    setOpenMenu(false);
                }}
                    className={user !== null ? "active" : "disabled"}
                >Emi Calculator
                </li>
            </ul>
        </div>
    );
}

export default ServiceMobile;
