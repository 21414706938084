import {
    FETCH_BRANDS_SUCCESS,
    FETCH_BRANDS_FAILURE
} from "../Actions/BrandSummaryAction";

const initialState = {
    brands: [],
    error: null
}

const brandSummaryReducers = (state = initialState, action) => {
    console.log('Action received:', action);
    switch (action.type) {
        case FETCH_BRANDS_SUCCESS:
            return {
                ...state,
                brands: action.payload,
                error: null,
            }
        case FETCH_BRANDS_FAILURE:
            return {
                ...state,
                brands: {},
                error: action.payload
            }
        default:
            return state;
    }
}

export default brandSummaryReducers;
