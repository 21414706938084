import {createContext, useContext, useState} from 'react';
import tokenService from "./TokenService";
import {useStore} from "./StoreContext";
import {fetchUser} from "../Actions/UserActions";

const TokenContext = createContext();

export const TokenProvider = ({children}) => {
    const [token, setToken] = useState(localStorage.getItem('token') || null);
    const store = useStore();
    const updateToken = (newToken) => {
        setToken(newToken);
        if (newToken !== null) {
            tokenService.setToken(newToken);
            store.dispatch(fetchUser(newToken));
        } else
            tokenService.removeToken();
    };

    return (
        <TokenContext.Provider value={{token, updateToken}}>
            {children}
        </TokenContext.Provider>
    );
};

export const useToken = () => {
    const context = useContext(TokenContext);
    if (!context) {
        throw new Error('useToken must be used within a TokenProvider');
    }
    return context;
};

